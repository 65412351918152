import * as LDClient from 'launchdarkly-js-client-sdk';
import bindAllMethods from '../../utils/bindAllMethods';
import EventNames from '../../config/eventNames';
import IFeatureFlagClient from './IFeatureFlagClient';
import { LaunchDarklyClientInitializeParams } from './types';
import { GetFeatureFlagParams } from './types';

export default class LaunchDarklyFeatureFlagClient
  implements IFeatureFlagClient
{
  private _launchDarklyClient: LDClient.LDClient;

  constructor() {
    bindAllMethods(this);
  }

  public async getFeatureFlag({
    key,
    defaultValue
  }: GetFeatureFlagParams): Promise<unknown> {
    if (!key || defaultValue === undefined) {
      console.error(`SyntaxError: The method getFeatureFlag requires key and default value to be called.
    Example: getFeatureFlag({ key: "myKey", defaultValue: true})`);
      throw new Error(`SyntaxError: The method getFeatureFlag requires key and default value to be called.
    Example: getFeatureFlag({ key: "myKey", defaultValue: true})`);
    }
    return this._launchDarklyClient.variation(key, defaultValue);
  }

  public async initializeClient(
    options: LaunchDarklyClientInitializeParams
  ): Promise<LDClient.LDClient> {
    const { clientId, context, eventService } = options;

    const client = LDClient.initialize(clientId, context);
    await client.waitForInitialization();
    client.setStreaming(true);

    client.on('change', () => {
      eventService.publish(EventNames.shellFeatureFlagChangedEventName, true);
    });

    this._launchDarklyClient = client;
    return client;
  }

  public getClient(): LDClient.LDClient {
    return this._launchDarklyClient;
  }
}
