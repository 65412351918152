import ITenantHandlerService from '../../services/tenantHandler/ITenantHandlerService';
import { tenantIdName } from '../../config/constants';
import { AuthContextEnum } from '../../services/authTokenService';
import authContextEnumToTenantLevel from '../../services/authTokenService/utils/authContextEnumToTenantLevel';
import { ISessionService } from '../../services/session';
import { getCookie } from '../../utils/cookies';
import { getStratusAccessToken } from '../../utils/getStratusAccessToken';
import { decodeJWTPayload } from '../../utils/tokenUtils/JWTUtils';
import { TokenType, UserContextEnum } from '../types';
import { internalLogger } from './logger';
import { LoginInputType } from '../../infra/commonInitializer/types';
import { INavigationService } from '../../services/navigationService';

let _sessionService: ISessionService;
let _tenantHandlerService: ITenantHandlerService;
let _navigationService: INavigationService;

export default async function authProvider(loginInput?: LoginInputType) {
  return {
    _setSessionService: (sessionService: ISessionService): void => {
      _sessionService = sessionService;
    },

    _setTenantHandlerService: (
      tenantHandlerService: ITenantHandlerService
    ): void => {
      _tenantHandlerService = tenantHandlerService;
    },

    _setNavigationService: (navigationService: INavigationService): void => {
      _navigationService = navigationService;
    },
    getAccessToken: async (forceRefresh?: boolean): Promise<string> => {
      if (forceRefresh) {
        try {
          await _sessionService.refresh({
            tenantsIdMap: _tenantHandlerService.getTenantIdsMap()
          });
        } catch (error) {
          internalLogger?.error(error);

          const { enabled: isLoginEnable = true } = loginInput || {};

          if (isLoginEnable) {
            await _sessionService.logout();
          }
        }
      }
      return getStratusAccessToken(TokenType.stratusAccessToken);
    },

    getIDToken: async (): Promise<string> => {
      return _sessionService.getIdToken();
    },

    /**
     * @deprecated Use v2.onTokenExchangeRequired() instead. Soon, v1.authProvider namespace will be deprecated.
     */
    onTokenExchangeRequired: async (
      userContext: UserContextEnum = UserContextEnum.organization,
      tenantId?: string
    ): Promise<void> => {
      console.warn(
        'This method is deprecated. Use v2.onTokenExchangeRequired() instead. Soon, v1.authProvider namespace will be deprecated.'
      );
      await _sessionService.refresh();

      const _tenantId = tenantId || getCookie(tenantIdName);
      let authContext;
      switch (userContext) {
        case UserContextEnum.customer:
          authContext = AuthContextEnum.subtenant;
          break;
        default:
          authContext = AuthContextEnum.tenant;
          break;
      }

      const level = authContextEnumToTenantLevel(authContext);
      await _tenantHandlerService.setTenant(_tenantId, level, {
        reload: false
      });
    },

    forceLogin: async () => {
      const postLoginRedirect =
        window.location.pathname + window.location.search;

      new Promise(() => {
        _navigationService.redirect(
          `/login?prompt=login+consent&postLoginRedirect=${postLoginRedirect}`
        );
      });
    },

    getDecodedUserStratusJWTPayload: (
      cookie = TokenType.stratusAccessToken
    ): any => {
      const userToken = getStratusAccessToken(cookie);
      if (!userToken) return undefined;

      return decodeJWTPayload(userToken);
    }
  };
}
