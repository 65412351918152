import MicrofrontendRouterOperationStateBuilder from '../../builder/MicrofrontendRouterOperationStateBuilder';
import matchPath from '../../../../../utils/matchPath';
import * as T from './types';

export default class LoginOperation implements T.IMicrofrontendRouterOperation {
  private navigationInterface: T.LoginOperationDependenciesType['navigationInterface'];
  private loginPathList = ['/login', '/loggedin', '/loggedout'];

  constructor(dependencies: T.LoginOperationDependenciesType) {
    this.navigationInterface = dependencies.navigationInterface;
  }

  // TODO: This is a specific scenario of PathOperation
  // TODO: This could be in a RouteService or something similar
  private isLoginPath(): boolean {
    let pathToCompare = this.navigationInterface?.location?.pathname;
    if (!pathToCompare.startsWith('/')) pathToCompare = '/' + pathToCompare;
    return matchPath(this.loginPathList, { exact: true, pathToCompare });
  }

  async process(
    stateBuilder: MicrofrontendRouterOperationStateBuilder
  ): Promise<void> {
    if (this.isLoginPath()) {
      stateBuilder.setContent({
        enable: false,
        isLogin: true
      });
      stateBuilder.setEndProcessChain(true);
    }
  }
}
