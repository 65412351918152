import MicrofrontendRouterOperationStateBuilder from '../../builder/MicrofrontendRouterOperationStateBuilder';
import * as T from './types';
import OnboardingAgent from './OnboardingAgent/OnboardingAgent';
import bindAllMethods from '../../../../../utils/bindAllMethods';
export default class OnboardingAgentOperation
  implements T.IMicrofrontendRouterOperation
{
  private _onboardingAgent: OnboardingAgent;

  constructor(dependencies: T.OnboardingAgentOperationDependenciesType) {
    bindAllMethods(this);
    this._onboardingAgent = new OnboardingAgent(dependencies);
  }

  async process(
    stateBuilder: MicrofrontendRouterOperationStateBuilder
  ): Promise<void> {
    await this._onboardingAgent.handleOnboardingState(stateBuilder);
  }
}
