import { serviceLaunchOptionsCacheKey } from '../../interface/v1/MicrofrontendRouter/operations/OnboardingAgentOperation/OnboardingAgent/OnboardingAgent';
import matchPath from '../../utils/matchPath';
import * as T from '../../interface/v1/MicrofrontendRouter/operations/OnboardingAgentOperation/OnboardingAgent/types';
import WebServiceRouting from '../../services/webServiceRouting';
import { Service } from '../../services/webServiceRouting/types';

// TODO: The methods from OnboardingAgent should be there.
// TODO: Update the naming to reference Onboarding (OnboardingLocaleContext)
export default class LocaleContext {
  private _webServiceRouting: WebServiceRouting;

  constructor(webServiceRouting: WebServiceRouting) {
    this._webServiceRouting = webServiceRouting;
  }

  public async findService(
    callback: (service: Service) => boolean
  ): Promise<Service> {
    const services = this._webServiceRouting.separateErrorObject(
      await this._webServiceRouting.getServices()
    )?.data?.services;

    return services?.find((service) => !!callback(service));
  }

  public async getServiceFromPath(path: string): Promise<Service> {
    return this.findService((service) => {
      if (service.path) {
        return matchPath(service.path, { exact: true, pathToCompare: path });
      }
      return false;
    });
  }

  private _getLocalCachedOnboardingAgentSession(): T.OnboardingInstanceSession {
    try {
      // TODO We could access this data using a repository.
      const stringfiedServiceLaunchOptions = window.sessionStorage.getItem(
        serviceLaunchOptionsCacheKey
      );

      if (stringfiedServiceLaunchOptions) {
        return JSON.parse(stringfiedServiceLaunchOptions);
      }
    } catch (error) {
      console.error(error);
    }
  }

  // TODO: onboardingTriggerList should be retrieved from commonsAdapter
  private _getOnboardingTriggerFromPath(path: string): T.OnboardingTrigger {
    const onboardingTriggerList =
      (window as any)?.Shell?.manifest?.services?.onboarding?.triggers || [];

    return onboardingTriggerList.find((trigger) => {
      return matchPath(trigger.path, { exact: true, pathToCompare: path });
    });
  }

  public async getLocaleContextByPath(
    currentPathname: string
  ): Promise<string> {
    // Option 1 -  Current Path is in a trigger path
    const triggerFromPath = this._getOnboardingTriggerFromPath(currentPathname);
    if (triggerFromPath?.localizationContextKey) {
      return triggerFromPath?.localizationContextKey;
    }

    // Option 2 -  Current Path belongs to an onboarding step
    const cachedOnboardingInstanceSession = triggerFromPath?.path
      ? undefined
      : this._getLocalCachedOnboardingAgentSession();

    const cachedServiceId =
      cachedOnboardingInstanceSession?.context?.nextService?.serviceId;
    const currentService = await this.getServiceFromPath(currentPathname);

    if (currentService && cachedServiceId) {
      return cachedOnboardingInstanceSession?.trigger?.localizationContextKey;
    }
    return undefined;
  }
}
