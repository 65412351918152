import { dataCollectionService } from '../dataCollectionService/dataCollectionService';

const logWithPrefix = (severity: Logtype, inputData: any) => {
  const configuration = dataCollectionService.getConfiguration();
  if (configuration?.verboseLogging) {
    return console[severity]('web-data-collection :',...inputData);
  }
};
export const logger = {
  debug: (...inputData: any) =>
    logWithPrefix(Logtype.debug, inputData),
  log: (...inputData: any) =>
    logWithPrefix(Logtype.log, inputData),
  info: (...inputData: any) =>
    logWithPrefix(Logtype.info, inputData),
  warn: (...inputData: any) =>
    logWithPrefix(Logtype.warn, inputData),
  error: (...inputData: any) =>
    logWithPrefix(Logtype.error, inputData),
};

enum Logtype {
  log = 'log',
  warn = 'warn',
  error = 'error',
  debug = 'debug',
  info = 'info',
}
