import bindAllMethods from '../../utils/bindAllMethods';
import { internalLogger } from '../../interface/v1/logger';
import { TenantStrategyEnum } from '../tenantHandler/strategy/strategy';
import ISessionService, { RefreshParameterType } from './ISessionService';
import { GenerateAuthenticationUrlParams, ILoginService } from './loginService';
import {
  GetProviderListParam,
  GetProviderListResponseType
} from '../../clients/shell/provider';
import { SetServiceDependencies } from '../../infra/commonInitializer/types';
import IRefreshTokenService from './refreshTokenService/IRefreshTokenService';
import IExchangeTokenService from './exchangeTokenService/IExchangeTokenService';
import { inject, singleton } from 'tsyringe';
import ExchangeTokenServiceNative from './exchangeTokenService/ExchangeTokenServiceNative';
import { getJWeb, JWebErrorHandler } from '../JWeb';
import { AuthContextEnum } from '../authTokenService';

@singleton()
class SessionServiceNative implements ISessionService {
  private _loginService: ILoginService;
  private _refreshTokenServiceNative: IRefreshTokenService;
  private _exchangeTokenServiceNative: IExchangeTokenService;
  private _legacySessionDataValue = {
    isLoggedIn: false,
    idToken: ''
  };

  constructor(
    @inject(ExchangeTokenServiceNative)
    exchangeTokenServiceNative: IExchangeTokenService,
    @inject('IRefreshTokenService')
    refreshTokenServiceNative: IRefreshTokenService
  ) {
    this._exchangeTokenServiceNative = exchangeTokenServiceNative;
    this._refreshTokenServiceNative = refreshTokenServiceNative;
    bindAllMethods(this);
  }

  public setDependencies({ services }: SetServiceDependencies): void {
    const { loginService } = services;
    this._loginService = loginService;

    bindAllMethods(this);
  }

  public async init(): Promise<void> {
    internalLogger.log('Initializing session service native.');

    await this._updateInitialState();
    await this._setStratusAccessTokenFromNative();

    return Promise.resolve();
  }

  public async refresh(
    refreshParameterType?: RefreshParameterType
  ): Promise<void> {
    if (!this.isLoggedIn()) {
      return;
    }
    return this._refreshTokenServiceNative.refresh(refreshParameterType);
  }

  public async exchangeToken(
    tenantId: string,
    authContext: AuthContextEnum,
    tenantStrategy: TenantStrategyEnum
  ): Promise<void> {
    return this._exchangeTokenServiceNative.exchange(
      tenantId,
      authContext,
      tenantStrategy
    );
  }

  public async clearSession(): Promise<void> {
    this._legacySessionDataValue.isLoggedIn = false;
    this._legacySessionDataValue.idToken = '';
    const Auth = await getJWeb()?.then?.((res) => res?.Plugins?.Auth);
    await Auth.logout().then((v) => JWebErrorHandler(v));
  }

  public async logout(): Promise<void> {
    await this.clearSession();
    window.location.reload();
  }

  public async getProviderList(
    options?: GetProviderListParam
  ): Promise<GetProviderListResponseType> {
    return this._loginService.getProviderList(options);
  }

  public async generateAuthenticationUrl(
    options: GenerateAuthenticationUrlParams
  ): Promise<string> {
    return this._loginService.generateAuthenticationUrl(options);
  }

  public isLoggedIn(): boolean {
    return !!this._legacySessionDataValue?.isLoggedIn;
  }

  public getIdToken(): string {
    return this._legacySessionDataValue?.idToken || '';
  }

  private async _updateInitialState(): Promise<void> {
    try {
      const jWebClient = await getJWeb();
      const isLoggedInData =
        (await jWebClient?.Plugins?.Auth?.isLoggedIn?.()) as { value: boolean };
      this._legacySessionDataValue.isLoggedIn = isLoggedInData.value;
    } catch (e) {
      console.error('Failed from get Auth data from JWeb.');
      console.error(e);
      this._legacySessionDataValue.isLoggedIn = false;
    }
  }

  private async _setStratusAccessTokenFromNative(): Promise<void> {
    try {
      if (this.isLoggedIn()) {
        return this._refreshTokenServiceNative.refresh();
      }
    } catch (error) {
      internalLogger.error('Error on refresh token');
      console.error(error);
      return;
    }
  }
}

export default SessionServiceNative;
