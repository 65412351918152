import { SetServiceDependencies } from '../../infra/commonInitializer/types';
import { base64Decode, base64Encode } from '../../utils/base64Encoder';

import bindAllMethods from '../../utils/bindAllMethods';
import { INavigationService } from '../navigationService';
import {
  CreatePostRedirectParamType,
  GetStatesParamOptionsType,
  PostRedirectType,
  StateParamObejectType,
  StatesParamType
} from './types';

export default class StateParamHandlerService {
  private postRedirectAblePaths = ['/loggedin', '/loggedout'];
  public postRedirectKeys = {
    postLoginRedirect: 'postLoginRedirect',
    postLogoutRedirect: 'postLogoutRedirect'
  };

  private _navigationService: INavigationService;

  constructor() {
    bindAllMethods(this);
  }

  setDependencies(options: SetServiceDependencies): void {
    const { services } = options;
    this._navigationService = services?.navigationService;
  }

  createLoginStateObject(postLoginRedirect?: string): StatesParamType {
    const stateSearchKey = 'state';
    const inviteSearchKey = 'invite';
    const stateValue = 'shell';
    const excludeKeys = [stateSearchKey, inviteSearchKey];
    const isUserInvite =
      new URLSearchParams(window.location.search).get(inviteSearchKey) ===
      'true';

    const postRedirect: PostRedirectType = postLoginRedirect && {
      key: this.postRedirectKeys.postLoginRedirect,
      value: postLoginRedirect
    };

    const includeParams = isUserInvite && {
      [inviteSearchKey]: isUserInvite.toString()
    };

    const stateObject = this.createStateParamObject({
      postRedirect,
      includeParams: {
        ...includeParams,
        state: stateValue
      },
      excludeKeys: excludeKeys
    });

    return {
      state: this.encodeStateParam(stateObject)
    };
  }

  createLogoutStateObject(postLogoutRedirect?: string): StatesParamType {
    const stateValue = 'shell';

    const postRedirect: PostRedirectType = postLogoutRedirect && {
      key: this.postRedirectKeys.postLogoutRedirect,
      value: postLogoutRedirect
    };

    const stateObject = this.createStateParamObject({
      postRedirect,
      includeParams: {
        state: stateValue
      }
    });

    return {
      ...stateObject,
      state: this.encodeStateParam(stateObject)
    };
  }

  createStateParamObject(
    params?: CreatePostRedirectParamType
  ): StateParamObejectType {
    const { postRedirect, excludeKeys = [], includeParams = {} } = params || {};
    const { key, value } = postRedirect || {};

    const stateObject = {};

    if (key && value) stateObject[key] = value;

    const searchParams = new URLSearchParams(window.location.search);
    searchParams?.forEach((paramValue, paramKey) => {
      if (!excludeKeys.find((key) => key === paramKey)) {
        stateObject[paramKey] = paramValue;
      }
    });

    return {
      ...stateObject,
      ...includeParams
    };
  }

  encodeStateParam(stateParamObject: StateParamObejectType): string {
    return base64Encode(stateParamObject);
  }

  decodeStateParam(stateParam: string): StateParamObejectType {
    return base64Decode(stateParam);
  }

  isPostRedirectable(): boolean {
    return this.isValidPath() && this.hasPostRedirectkey();
  }

  isValidPath(): boolean {
    const currentPath = window.location.pathname;

    return this.postRedirectAblePaths.some((validPath) =>
      currentPath.includes(validPath)
    );
  }

  hasPostRedirectkey(): boolean {
    const stateParamDecoded = this._getStateParam({ decode: true });

    const stateParamsObject = new URLSearchParams(stateParamDecoded);
    return Object.keys(this.postRedirectKeys).some((value) => {
      return stateParamsObject?.get(value);
    });
  }

  handleRedirect(): void {
    const stateParamDecoded = this._getStateParam({ decode: true });
    const stateParamsObject = new URLSearchParams(stateParamDecoded);

    const postRedirect = stateParamsObject?.get(
      this.postRedirectKeys.postLogoutRedirect
    );
    if (this.isValidPath() && postRedirect) {
      this.redirect(postRedirect);
    }
  }

  private _getStateParam(options?: GetStatesParamOptionsType) {
    const { decode } = options || {};
    const searchParams = new URLSearchParams(window.location.search);
    const stateParamEncoded = searchParams?.get('state');
    return decode
      ? this.decodeStateParam(stateParamEncoded)
      : stateParamEncoded;
  }

  private redirect(postRedirect: string) {
    this._navigationService.redirect(postRedirect);
  }
}
