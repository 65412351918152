import MicrofrontendRouterOperationStateBuilder from '../../builder/MicrofrontendRouterOperationStateBuilder';
import {
  IRoutesService,
  RouteServiceInputType
} from '../../../../../services/RoutesService';
import * as T from './types';
import { getServices } from '../../../../../infra/commonInitializer';
import { shouldPreloadAssetReferenceLocale } from '../shouldPreloadAssetReferenceLocale';
import { INavigationService } from '../../../../../services/navigationService';
import isLoginRequired from './../utils/isLoginRequired';
import { FallbackInterfaceType } from '../../../../../interface/v1/Fallback/types';

/**
 * The operation that is responsible for matching the current path with the route and returning its object.
 */
export default class PathRouteOperation
  implements T.IMicrofrontendRouterOperation
{
  private userSessionInterface: T.PathRouteOperationDependenciesType['userSessionInterface'];
  private routesService: IRoutesService;
  private navigationService: INavigationService;
  private fallbackInterface: FallbackInterfaceType;
  private globalPreloadAssetReferenceLocale;

  constructor(dependencies: T.PathRouteOperationDependenciesType) {
    const services = getServices();
    this.routesService = services?.routesService;
    this.navigationService = services?.navigationService;
    this.userSessionInterface = dependencies.userSessionInterface;
    this.fallbackInterface = dependencies.fallbackInterface;

    // TODO: this could came from localizationService;
    this.globalPreloadAssetReferenceLocale =
      dependencies.globalPreloadAssetReferenceLocale;
  }

  private getRouteByCurrentPath() {
    const currentPath = this.navigationService.getHistory().location.pathname;
    return this.routesService.findRouteWithPriority(currentPath);
  }

  private processContent(
    stateBuilder: MicrofrontendRouterOperationStateBuilder,
    route: RouteServiceInputType
  ) {
    // get info if will be preloaded the locale files
    const willPreloadLocale = shouldPreloadAssetReferenceLocale({
      globalPreload: this.globalPreloadAssetReferenceLocale,
      localPreload: route?.preloadAssetReferenceLocale
    });

    // content flow
    stateBuilder.setContent({
      enable: !!route?.assetReference,
      preloadAssetReferenceLocale: willPreloadLocale,
      assetReference: route?.assetReference,
      criterionKey: route?.criterionKey,
      properties: route?.properties,
      key: route?.key,
      path: route?.path,
      // TODO: remove this properties when possible
      isPathRoute: true,
      label: route?.label,
      public: !!route?.public,
      tenantHandlerOverride: route?.tenantHandlerOverride,
      skeletonReference: route?.skeletonReference
    });
  }

  private processLayout(
    stateBuilder: MicrofrontendRouterOperationStateBuilder,
    route: RouteServiceInputType
  ) {
    // case #1: layoutKey is defined
    if (typeof route?.layoutKey === 'string' || route?.layoutKey === false) {
      stateBuilder.setLayoutByKey(route?.layoutKey);
      return;
    }

    // case #2: layoutKey is undefined
    // will be used the default deprecated layout from react-nav
    if (route?.public || route?.hidden) {
      stateBuilder.setLayoutByKey(false);
      return;
    }

    // case #3: layoutKey is undefined and not public nor hidden
    // will be used the default deprecated layout from react-nav
    stateBuilder.setLayout({
      enable: true,
      useDefaultRouteLayout: true
    });
  }

  private processToLogin(
    stateBuilder: MicrofrontendRouterOperationStateBuilder
  ) {
    // Redirect Flow To Login
    // TODO: This could be in Super Class.
    // TODO: It could retrieve from a service
    const loginPath = this.userSessionInterface.getLoginPath();

    stateBuilder.setRedirectTo(loginPath);
    stateBuilder.setEndProcessChain(true);
  }

  /**
   *  The main method to process the operation
   */
  async process(
    stateBuilder: MicrofrontendRouterOperationStateBuilder
  ): Promise<void> {
    // main data to be analyzed (Route in this case)
    const route = await this.getRouteByCurrentPath();

    // without assetReference, won't update the screen
    if (route?.assetReference) {
      const wasLoginRequired = await isLoginRequired(route);
      if (wasLoginRequired) {
        this.processToLogin(stateBuilder);
      } else {
        this.processContent(stateBuilder, route);

        this.processLayout(stateBuilder, route);
      }
    }

    // TODO: This should be in a fallback operation.
    // If in that point, there's no content, then will be redirected to fallback page.
    const redirectToDefaultFallback = !stateBuilder.getState()?.content?.enable;
    if (redirectToDefaultFallback) {
      const redirectPath =
        this.fallbackInterface.getDefaultRootFallback()?.redirectTo;

      // Sending the user to the global fallback redirect
      stateBuilder.setRedirectTo(redirectPath);
    }
  }
}
