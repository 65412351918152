import { SpanType } from '../../../../../../services/monitoringService/types';
import { OnboardingInstanceSession, OnboardingInstanceType } from './newTypes';
import { ServiceInstanceState } from '../../../../../../services/JWeb/JWebEnums';
import { IMonitoringService } from '../../../../../../services/monitoringService';

interface SplunkRumManagerConstructorProps {
  monitoringService: IMonitoringService;
}

export default class SplunkRumManager {
  private _splunkRumSpan: SpanType;
  private _monitoringService: IMonitoringService;

  constructor({ monitoringService }: SplunkRumManagerConstructorProps) {
    this._monitoringService = monitoringService;
  }

  private endSpan = () => {
    if (this._splunkRumSpan) {
      this._splunkRumSpan?.end?.();
      this._splunkRumSpan = undefined as any;
    }
  };

  private buildEvent({ state, serviceId }): string {
    if (!serviceId) {
      if (state === 'launching') return `onboarding-instance-starting`;
      else if (state === 'closed') return `onboarding-instance-closing`;
      else return `onboarding-instance-failed`;
    } else if (state === 'running') {
      return `launching-${serviceId}`;
    } else if (state === 'failedToLaunch') {
      return `failed-to-finalize-${serviceId}`;
    } else {
      return `finalizing-${serviceId}`;
    }
  }

  startOnboardingSpan = (
    session: OnboardingInstanceSession,
    state: OnboardingInstanceType['state']
  ): void => {
    this.endSpan();

    const { exitUrl, resultUrl, serviceId } =
      session?.context?.nextService || {};
    const { sessionId } = session?.context?.sessionContext || {};

    const eventName = this.buildEvent({ state, serviceId });

    this._splunkRumSpan = this._monitoringService?.startSpan?.(
      'onboarding-agent',
      {
        'onboarding.serviceId': serviceId,
        'onboarding.state': state,
        'onboarding.exitUrl': exitUrl,
        'onboarding.resultUrl': resultUrl,
        'onboarding.sessionId': sessionId,
        'workflow.name': eventName
      }
    );

    if (state === ServiceInstanceState.closed) {
      this.endSpan();
    }
  };
}
