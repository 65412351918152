import bindAllMethods from '../../utils/bindAllMethods';
import {
  ExchangeTokenDTOType,
  ExchangeTokenResponseType,
  ExchangeV3Client,
  IExchangeClient
} from '../../clients/shell/exchange';
import IRefreshClient from '../../clients/shell/refresh/IRefreshClient';
import { TokenType, UserContextEnum } from '../../interface/types';
import { clearUserData } from './utils/clearUserData';
import { getCookie } from '../../utils/cookies';
import { AuthTokenService, IAuthTokenService } from '../authTokenService';
import AuthContextEnum from '../authTokenService/AuthContextEnum';
import getParentContext from '../authTokenService/utils/getParentContext';
import ISupportSessionService from '../supportSession/ISupportSessionService';
import { TenantStrategyEnum } from '../tenantHandler/strategy/strategy';
import ISessionService, {
  LogoutOptionsType,
  RefreshParameterType
} from './ISessionService';
import SessionObserver, { SessionEvents } from './SessionObserver';
import { ILogoutService } from './logoutService';
import { GenerateAuthenticationUrlParams, ILoginService } from './loginService';
import {
  GetProviderListParam,
  GetProviderListResponseType
} from '../../clients/shell/provider';
import IdTokenRepository from './IdTokenRepository';
import { SetServiceDependencies } from '../../infra/commonInitializer/types';
import IRefreshTokenService from './refreshTokenService/IRefreshTokenService';
import { inject, singleton } from 'tsyringe';
import { SupportSessionService } from '../supportSession';

export type SessionServiceParameters = {
  refreshClient: IRefreshClient;
  exchangeClient: IExchangeClient;
  supportSessionService: ISupportSessionService;
  authTokenService: IAuthTokenService;
  idTokenRepository: IdTokenRepository;
};

@singleton()
class SessionServiceWeb implements ISessionService {
  private _exchangeClient: IExchangeClient;
  private _supportSessionService: ISupportSessionService;
  private _authTokenService: IAuthTokenService;
  private _loginService: ILoginService;
  private _logoutService: ILogoutService;
  private _idTokenRepository: IdTokenRepository;
  private _refreshTokenServiceWeb: IRefreshTokenService;

  constructor(
    @inject(IdTokenRepository) idTokenRepository: IdTokenRepository,
    @inject(AuthTokenService) authTokenService: IAuthTokenService,
    @inject(ExchangeV3Client) exchangeClient: IExchangeClient,
    @inject(SupportSessionService)
    supportSessionService: ISupportSessionService,
    @inject('IRefreshTokenService') refreshTokenServiceWeb: IRefreshTokenService
  ) {
    this._idTokenRepository = idTokenRepository;
    this._authTokenService = authTokenService;
    this._exchangeClient = exchangeClient;
    this._supportSessionService = supportSessionService;
    this._refreshTokenServiceWeb = refreshTokenServiceWeb;
    bindAllMethods(this);
  }

  public setDependencies({ services }: SetServiceDependencies): void {
    const { loginService, logoutService } = services;
    this._loginService = loginService;
    this._logoutService = logoutService;

    bindAllMethods(this);
  }

  public async init(): Promise<void> {
    await this._validateSupportSession();
  }

  // TODO: Refactor this to not clearUserData if it's not loggedIn and create a mechanism to deal with that
  // TODO: Refactor to deal with the cookies
  public isLoggedIn(): boolean {
    //TODO: move cookie name to a constant
    const maxAge0OnLoginCookie = getCookie(
      'jarvisreactshelllogin__setMaxAge0OnLogin'
    );

    const isAST = this._supportSessionService.isSupportSession();
    const isLoggedIn =
      (maxAge0OnLoginCookie !== 'true' && this._isSessionActive()) || isAST;

    if (!isLoggedIn) clearUserData();
    return isLoggedIn;
  }

  public async refresh(
    refreshParameterType?: RefreshParameterType
  ): Promise<void> {
    return this._refreshTokenServiceWeb.refresh(refreshParameterType);
  }

  private _isSessionActive(): boolean {
    return (
      !!getCookie(TokenType.shellSessionActive, false) ||
      !!getCookie(TokenType.shellSessionId, false)
    );
  }

  public getIdToken(): string {
    return (
      this._idTokenRepository?.findOne()?.token ||
      getCookie(TokenType.stratusIdToken) ||
      getCookie(TokenType.deprecatedstratusIdToken)
    );
  }

  public async exchangeToken(
    tenantId: string,
    authContext: AuthContextEnum,
    tenantStrategy: TenantStrategyEnum
  ): Promise<void> {
    let exchangeTenantTokenResponse: ExchangeTokenResponseType;
    try {
      const parentContext = getParentContext(authContext);

      const exchangeDTO: ExchangeTokenDTOType = {
        accessToken: this._getAccessToken(parentContext),
        tenantId,
        tenantStrategy
      };
      exchangeTenantTokenResponse = await this._exchangeClient.exchangeToken(
        exchangeDTO
      );
    } catch (err) {
      console.error(err);
    }
    await this._saveExchangeData(exchangeTenantTokenResponse);
    await SessionObserver.notify(
      SessionEvents.EXCHANGE_TENANT_TOKEN,
      this._authTokenService.getToken()?.token
    );
  }

  public async clearSession(): Promise<void> {
    clearUserData();
    this._logoutService.logout();
  }

  public async logout(options?: LogoutOptionsType): Promise<void> {
    const { postLogoutRedirect } = options || {};

    return this._logoutService.logout({ postLogoutRedirect });
  }

  public async getProviderList(
    options?: GetProviderListParam
  ): Promise<GetProviderListResponseType> {
    return this._loginService.getProviderList(options);
  }
  public async generateAuthenticationUrl(
    options: GenerateAuthenticationUrlParams
  ): Promise<string> {
    return this._loginService.generateAuthenticationUrl(options);
  }

  private _getAccessToken(authContext: AuthContextEnum): string {
    const { token: accessToken } =
      this._authTokenService.getToken(authContext) || {};
    return accessToken;
  }

  private async _saveExchangeData(
    exchangeTenantTokenResponse: ExchangeTokenResponseType
  ): Promise<void> {
    let authContext: AuthContextEnum;
    if (exchangeTenantTokenResponse.tokenType === UserContextEnum.customer) {
      authContext = AuthContextEnum.subtenant;
    } else {
      authContext = AuthContextEnum.tenant;
    }
    this._authTokenService.setToken(
      exchangeTenantTokenResponse.accessToken,
      authContext
    );
  }

  private async _validateSupportSession(): Promise<void> {
    if (
      this._supportSessionService.isSupportSessionStartedBySessionAPIAndInitialStatusNotEnabled()
    ) {
      await this.clearSession();
    }
  }
}

export default SessionServiceWeb;
