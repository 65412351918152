import { isNative as isNativeFromJWeb } from '../../services/JWeb';
import { getServices } from '../../infra/commonInitializer';
import {
  KeepLatestTenantOnNewTabs,
  lastTenantIdsRepository
} from './KeepLatestTenantOnNewTabs';
import InitializeContextServices from './initializeContextsServices';
import UseTenantIdsFromQueryParams from './useTenantIdsFromQueryParams';
import WriteCookieDataToValueStore from './writeCookieDataToValueStore';
import SetFirstApplicationToken from './setFirstApplicationToken';
import SetSupportSessionToken from './setSupportSessionToken';
import UseRepositoriesManagementRules from './useRepositoriesManagementRules';
import UsePostRedirectService from './usePostRedirect';
import ServiceWorkerRules from './ServiceWorkerRules';
import EventsBehaviors from './EventsBehaviors';
import RemoveMaxAgeCookieInLoggedIn from './removeMaxAgeCookieInLoggedIn';

export default class BeforeInitBehaviors {
  public static async init(): Promise<void> {
    const isNative = await isNativeFromJWeb();
    const services = getServices();
    const {
      appContext,
      tenantHandlerService,
      navigationService,
      sessionService,
      authTokenService,
      supportSessionService,
      stateParamHandlerService,
      serviceWorkerService,
      eventService
    } = services;

    const removeMaxAgeCookieInLoggedIn = new RemoveMaxAgeCookieInLoggedIn();

    const setFirstApplicationToken =
      !isNative &&
      new SetFirstApplicationToken({
        sessionService,
        authTokenService,
        supportSessionService
      });

    const setSupportSessionToken =
      !isNative &&
      new SetSupportSessionToken({ supportSessionService, authTokenService });

    const initializeContextServices = new InitializeContextServices({
      appContext
    });

    const keepLatestTenantOnNewTabs =
      !isNative &&
      new KeepLatestTenantOnNewTabs({
        repository: lastTenantIdsRepository,
        sessionService,
        tenantHandler: tenantHandlerService
      });

    const useTenantIdsFromQueryParams =
      !isNative &&
      new UseTenantIdsFromQueryParams({
        tenantHandlerService,
        navigationService,
        sessionService
      });

    const writeCookieDataToValueStore =
      !isNative && new WriteCookieDataToValueStore();

    const useRepositoriesManagementRules = new UseRepositoriesManagementRules({
      sessionService
    });

    const usePostRedirectService = new UsePostRedirectService({
      stateParamHandlerService
    });

    const events = new EventsBehaviors({
      eventService,
      navigationService
    });

    const serviceWorkerRules = new ServiceWorkerRules({
      serviceWorkerService,
      sessionService
    });

    removeMaxAgeCookieInLoggedIn?.init?.();
    useRepositoriesManagementRules?.init?.();
    await initializeContextServices?.init?.();
    await keepLatestTenantOnNewTabs?.init?.();
    await setFirstApplicationToken?.init?.();
    await setSupportSessionToken?.init?.();
    await useTenantIdsFromQueryParams?.init?.();
    await writeCookieDataToValueStore?.init?.();
    usePostRedirectService.init?.();
    serviceWorkerRules?.init?.();
    events?.init?.();
  }
}
