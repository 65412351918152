import bindAllMethods from '../../../utils/bindAllMethods';
import { CheckReturnObjectType } from '../Criterion/types';
import MicrofrontendRouterOperationStateBuilder from './builder/MicrofrontendRouterOperationStateBuilder';
import * as T from './types';
import matchPath from '../../../utils/matchPath';

export default class MicrofrontendRouter {
  private operations: T.IMicrofrontendRouterOperation[];
  private layoutInterface: T.MicrofrontendRouterDependenciesType['layoutInterface'];
  private criterionInterface: T.MicrofrontendRouterDependenciesType['criterionInterface'];
  private fallbackInterface: T.MicrofrontendRouterDependenciesType['fallbackInterface'];
  private navigationInterface: T.MicrofrontendRouterDependenciesType['navigationInterface'];
  private eventService: T.MicrofrontendRouterDependenciesType['event']['eventService'];
  private eventList: T.MicrofrontendRouterDependenciesType['event']['eventList'];
  private started = false;
  private state: T.MicrofrontendRouterStateType = {};
  private updateCurrentMicrofrontendPromiseId = 0;
  private setStatePromiseId = 0;
  private tenantHandlerService: T.MicrofrontendRouterDependenciesType['tenantHandlerService'];
  eventName: T.MicrofrontendRouterDependenciesType['event']['eventName'];

  constructor({
    event,
    operations = [],
    navigationInterface,
    layoutInterface,
    criterionInterface,
    fallbackInterface,
    tenantHandlerService
  }: T.MicrofrontendRouterDependenciesType) {
    this.layoutInterface = layoutInterface;
    this.fallbackInterface = fallbackInterface;
    this.navigationInterface = navigationInterface;
    this.criterionInterface = criterionInterface;
    this.operations = operations;
    this.eventService = event.eventService;
    this.eventName = event.eventName;
    this.eventList = event.eventList;
    this.tenantHandlerService = tenantHandlerService;

    bindAllMethods(this);
  }

  // TODO: This method exists only because onboarding agent is initialized after shell-commons
  // it should be removed on future when we have a centralized solution
  async start(): Promise<void> {
    if (
      !this.started &&
      Array.isArray(this?.eventList) &&
      !!this.eventService
    ) {
      const syncData = async () => {
        await this.updateState();
      };

      this.started = true;
      this.eventList.forEach((key) =>
        this.eventService.addEventListener(key, syncData)
      );

      return syncData();
    }
  }

  private async checkCriterion(
    criterionKey?: string | false
  ): Promise<CheckReturnObjectType> {
    if (!criterionKey)
      return {
        result: true
      };

    const criterion =
      await this.criterionInterface.checkAdditionalCriterionDataByKey(
        criterionKey
      );

    return criterion;
  }

  private async setState(data: T.MicrofrontendRouterOperationStateType) {
    const thisPromiseId = this.setStatePromiseId + 1;
    this.setStatePromiseId = thisPromiseId;

    if (data?.redirectTo) {
      const pathToCompare = this.navigationInterface.location.pathname;
      const isSamePath = matchPath(data?.redirectTo, {
        exact: true,
        pathToCompare
      });
      if (!isSamePath) {
        this.navigationInterface.push(data.redirectTo);
      }
    } else {
      const tenantHandlerOverride = data?.content?.tenantHandlerOverride;
      this.tenantHandlerService.setTenantHandlerKey(tenantHandlerOverride);

      const contentCriterion = await this.checkCriterion(
        data?.content?.criterionKey
      );

      if (!contentCriterion?.result) {
        this.fallbackInterface.setCurrentFallbackByKey(
          contentCriterion?.fallbackKey
        );
      }

      const getMicrofrontendObject = async (
        newMicrofrontendRouterAsset: T.MicrofrontendRouterAssetType,
        currentMicrofrontendRouterAsset: T.MicrofrontendRouterAssetType
      ) => {
        const criterion = await this.checkCriterion(
          newMicrofrontendRouterAsset?.criterionKey
        );

        if (criterion?.result) {
          // Keep old object reference if the new object have the same values
          const isDifferentValue =
            JSON.stringify(newMicrofrontendRouterAsset) !==
            JSON.stringify(currentMicrofrontendRouterAsset);

          if (isDifferentValue) {
            return newMicrofrontendRouterAsset;
          } else {
            return currentMicrofrontendRouterAsset;
          }
        }
      };

      const newState: T.MicrofrontendRouterStateType = {
        content: await getMicrofrontendObject(
          data?.content,
          this.state?.content
        ),
        layout: await getMicrofrontendObject(data?.layout, this.state?.layout),
        modalContent: await getMicrofrontendObject(
          data?.modalContent,
          this.state?.modalContent
        )
      };

      const isLastPromise = thisPromiseId === this.setStatePromiseId;
      const isDifferentValue =
        JSON.stringify(newState) !== JSON.stringify(this.state);

      if (isLastPromise && isDifferentValue) {
        this.state = newState;
        this.eventService.publish(this.eventName, { data: this.state });
      }
    }
  }

  private async updateState() {
    const stateBuilder = new MicrofrontendRouterOperationStateBuilder({});
    const thisPromiseId = this.updateCurrentMicrofrontendPromiseId + 1;
    this.updateCurrentMicrofrontendPromiseId = thisPromiseId;

    const recursiveOperationProcess = async (
      operations: T.IMicrofrontendRouterOperation[],
      promiseId: number
    ) => {
      const [thisOperation, ...nextOperations] = operations;

      await thisOperation?.process?.(stateBuilder);
      const thisState = stateBuilder.getState();

      if (
        !thisState?.endProcessChain &&
        Array.isArray(nextOperations) &&
        nextOperations.length > 0 &&
        this.updateCurrentMicrofrontendPromiseId === promiseId
      ) {
        await recursiveOperationProcess(nextOperations, promiseId);
      }
    };

    await recursiveOperationProcess(this.operations, thisPromiseId);

    if (this.updateCurrentMicrofrontendPromiseId === thisPromiseId) {
      const newState = stateBuilder.getState();
      await this.setState(newState);
    }
  }

  getState(): T.MicrofrontendRouterStateType {
    return this.state;
  }

  listen(
    callback: (state: T.MicrofrontendRouterStateType) => void
  ): () => void {
    const callbackProxy = async () => {
      callback?.(this.getState());
    };

    this.eventService?.addEventListener?.(this.eventName, callbackProxy);

    return () =>
      this.eventService?.removeEventListener?.(this.eventName, callbackProxy);
  }

  useReactHook(React: any): T.MicrofrontendRouterStateType {
    const [state, setState] = React.useState(this.getState());

    React.useEffect(() => {
      const removeListener = this.listen((value) => setState(value));

      return () => removeListener();
    }, []);

    return state;
  }
}
