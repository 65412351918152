import * as T from './INavigationService';
import { ILocalizationService } from '../localizationService';
import bindAllMethods from '../../utils/bindAllMethods';
import { internalLogger } from '../../interface/v1/logger';
import { IURLService } from '../URLService';
import WebNavigationHistory from './navigationHistoryStrategies/WebNavigationHistory/WebNavigationHistory';
import { singleton } from 'tsyringe';

export type NavigationServicesParams = {
  basePath: string;
  navigation: {
    removeLocaleUrl?: boolean;
  };
};

@singleton()
export default class NavigationService implements T.INavigationService {
  // TODO: Change it to be private on future after we are able to not provide it as interface
  _history: WebNavigationHistory;
  private _localizationService: ILocalizationService;
  private _URLService: IURLService;
  private _shellState = {
    name: 'shellState',
    value: ''
  };
  private _persistedQueryParamMap: Map<
    string,
    T.PersistQueryParamGetValueType
  > = new Map();
  get length(): T.INavigationService['length'] {
    return this?._history?.length;
  }
  get action(): T.INavigationService['action'] {
    return this?._history?.action;
  }
  get location(): T.INavigationService['location'] {
    return this?._history?.location;
  }

  // TODO: Uncomment this after we are able to remove _overrideNavigationMethods
  push(
    location: Parameters<T.INavigationService['push']>[0],
    state?: Parameters<T.INavigationService['push']>[1]
  ): ReturnType<T.INavigationService['push']> {
    // const newLocation = this._locationPersistedQueryParamHandler(location);

    // this._history?.push?.(newLocation, state);
    this._history?.push?.(location, state);
  }

  // TODO: Uncomment this after we are able to remove _overrideNavigationMethods
  replace(
    location: Parameters<T.INavigationService['replace']>[0],
    state?: Parameters<T.INavigationService['replace']>[1]
  ): ReturnType<T.INavigationService['replace']> {
    // const newLocation = this._locationPersistedQueryParamHandler(location);

    // this._history?.replace?.(newLocation, state);
    this._history?.replace?.(location, state);
  }

  go(
    n: Parameters<T.INavigationService['go']>[0]
  ): ReturnType<T.INavigationService['go']> {
    this._history?.go?.(n);
  }

  goBack(): ReturnType<T.INavigationService['goBack']> {
    this._history?.goBack?.();
  }

  goForward(): ReturnType<T.INavigationService['goForward']> {
    this._history?.goForward?.();
  }

  block(
    prompt?: Parameters<T.INavigationService['block']>[0]
  ): ReturnType<T.INavigationService['block']> {
    return this._history?.block?.(prompt);
  }

  listen(
    listener: Parameters<T.INavigationService['listen']>[0]
  ): ReturnType<T.INavigationService['listen']> {
    return this._history?.listen?.(listener);
  }

  createHref(
    location: Parameters<T.INavigationService['createHref']>[0]
  ): ReturnType<T.INavigationService['createHref']> {
    return this._history?.createHref?.(location);
  }

  /** Use this method if you need refresh the whole page.
   * It also keeps the queryparams and locale. */
  redirect(url: string): void {
    const isSameOrigin = (url: string): boolean => {
      const parsedUrl = new URL(url);
      return parsedUrl?.origin === window?.location?.origin;
    };

    const getRedirectUrl = (url: string): string => {
      const redirectUrl = url;

      const hasProtocol = url.startsWith('http') || url.startsWith('https');

      const isFullUrlWithSameOrigin = hasProtocol && isSameOrigin(redirectUrl);
      const isValidPath = url.startsWith('/');

      if (isValidPath || isFullUrlWithSameOrigin) {
        const urlObject = new URL(redirectUrl, window.location.origin);

        const redirectUrlWithLocale = this._createPathWithLocale({
          pathname: urlObject.pathname,
          search: urlObject.search,
          hash: urlObject.hash
        });

        const redirectUrlDescription = this._URLService.getPathDescription(
          redirectUrlWithLocale
        );

        const newLocation = this._locationPersistedQueryParamHandler(
          redirectUrlWithLocale
        );

        const locale = `/${redirectUrlDescription.country}/${redirectUrlDescription.language}`;

        return (
          locale + newLocation.pathname + newLocation.search + newLocation.hash
        );
      }

      return redirectUrl;
    };
    window.location.href = getRedirectUrl(url);
  }

  public setPersistQueryParam(
    id: Parameters<T.INavigationService['setPersistQueryParam']>[0],
    getValue: Parameters<T.INavigationService['setPersistQueryParam']>[1]
  ): ReturnType<T.INavigationService['setPersistQueryParam']> {
    if (typeof id !== 'string' || id.length === 0) {
      console.error(
        'Navigation.persistQueryParam: The id must be a non-empty string.'
      );
    } else if (typeof getValue !== 'function') {
      console.error(
        'Navigation.persistQueryParam: The getValue and setValue functions are required.'
      );
    } else {
      this._persistedQueryParamMap.set(id, getValue);
    }
  }

  public setDependencies({
    services
  }: Parameters<T.INavigationService['setDependencies']>[0]): ReturnType<
    T.INavigationService['setDependencies']
  > {
    const { localizationService, URLService } = services;
    this._localizationService = localizationService;
    this._URLService = URLService;
    bindAllMethods(this);
  }

  public init(): ReturnType<T.INavigationService['init']> {
    // TODO: This should be a JSHELL's use case.
    // The localization must been finished at this point.

    this._addShellStatePersistance();
    this._createHistory();
  }

  public async refreshPath(): Promise<void> {
    const newPath = this._URLService.createPath({
      language: this._localizationService?.language,
      country: this._localizationService?.country,
      pathname: this._history.location.pathname,
      search: this._history.location.search,
      hash: this._history.location.hash
    });

    await new Promise(() => {
      window.location.href = newPath;
    });
  }

  /**
   * @deprecated
   * Remove it when we are able to not provide history as interface
   * */
  public getHistory(): ReturnType<T.INavigationService['getHistory']> {
    return this?._history?._history;
  }

  private _createHistory(): void {
    const basePath = this._createPathWithLocale();

    this._history = new WebNavigationHistory({ basePath });
    // TODO: Delete this and uncomment push and replace code after we are able to not provide history as interface
    this._overrideNavigationMethods();

    const currentPathDescription = this._URLService.getCurrentPathDescription();
    const servicePath =
      currentPathDescription.pathname +
      currentPathDescription.search +
      currentPathDescription.hash;

    this._history.replace(servicePath);

    internalLogger?.log?.('A new History was created. BasePath: ' + basePath);
  }

  private _addShellStatePersistance() {
    const currentPathDescription = this._URLService.getCurrentPathDescription();
    const searchObject = this._URLService.convertSearchStringToObject(
      currentPathDescription?.search
    );
    this._shellState.value =
      searchObject?.[this._shellState.name] || this._shellState.value;

    this.setPersistQueryParam(this._shellState.name, (queryParamObject) => {
      this._shellState.value =
        queryParamObject?.[this._shellState.name] || this._shellState.value;

      return {
        ...queryParamObject,
        [this._shellState.name]: this._shellState.value
      };
    });
  }

  private _createPathWithLocale(
    path?: string | { pathname?: string; search?: string; hash?: string }
  ): string {
    const { language, country } = this._localizationService;

    let pathname: string;
    let search: string;
    let hash: string;

    if (typeof path === 'string') {
      const pathDescription = this._URLService.getPathDescription(path);
      pathname = pathDescription.pathname;
      search = pathDescription.search;
      hash = pathDescription.hash;
    } else {
      pathname = path?.pathname;
      search = path?.search;
      hash = path?.hash;
    }

    return this._URLService.createPath({
      language,
      country,
      pathname,
      search,
      hash
    });
  }

  private _locationPersistedQueryParamHandler(
    location: T.NavigationHistoryLocationDescriptor
  ): T.NavigationHistoryLocationDescriptorObject {
    const newPathBasedOnLocation = this._createPathWithLocale(location);

    const pathDescription = this._URLService.getPathDescription(
      newPathBasedOnLocation
    );

    let searchObject = this._URLService.convertSearchStringToObject(
      pathDescription.search
    );

    this._persistedQueryParamMap.forEach((getValue) => {
      const newValue = getValue(searchObject) || {};

      searchObject = newValue;
    });

    pathDescription.search =
      this._URLService.convertSearchObjectToString(searchObject);

    return {
      hash: pathDescription.hash,
      pathname: pathDescription.pathname,
      search: pathDescription.search,
      key: location?.['key'],
      state: location?.['state']
    };
  }

  // TODO: Delete this and uncomment push and replace code after we are able to not provide history as interface
  private _overrideNavigationMethods(): void {
    const methodsToOverride = ['push', 'replace'] as const;

    methodsToOverride.forEach((method) => {
      const history = this.getHistory();
      const oldMethod = history[method].bind(history);

      history[method] = (location, state) => {
        const newLocation = this._locationPersistedQueryParamHandler(location);

        oldMethod(newLocation, state);
      };
    });
  }
}
