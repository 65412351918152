import FallbackOperation from './operations/FallbackOperation';
import LoginOperation from './operations/LoginOperation';
import OnboardingAgentOperation from './operations/OnboardingAgentOperation';
import PathRouteOperation from './operations/PathRouteOperation';
import MicrofrontendRouterInterface from '.';
import Store from '../store';
import Fallback from '../Fallback';
import Criterion from '../Criterion';
import Layout from '../Layout';
import { UserSessionInterfaceType } from '../userSession/types';
import { Stack } from '../../../types/stratus';
import localization from '../localization';
import authProvider from '../auth';
import { PromiseReturnType } from '../../../types/typeHandlers';
import { ISessionService } from '../../../services/session';
import { getServices } from '../../../infra/commonInitializer';
import { IMonitoringService } from '../../../services/monitoringService';
import { IUserService } from '../../../services/userService';
import ServiceRoutingOperation from './operations/ServiceRoutingOperation';
import ITenantHandlerService from '../../../services/tenantHandler/ITenantHandlerService';
import {
  LocalizationServiceInputType,
  OnboardingInputType
} from '../../../infra/commonInitializer/types';
import { NavigationInterfaceType } from '../navigation/types';
import { WebServiceRoutingEvents } from '../../../services/webServiceRouting';

let microfrontendRouterInterface: MicrofrontendRouterInterface;

export function getMicrofrontendRouterInterface(): MicrofrontendRouterInterface {
  return microfrontendRouterInterface;
}

// This could be in MFE Router Service
const createRelatedEvents = ({
  eventName,
  fallbackInterface,
  criterionInterface,
  storeInterface,
  eventService,
  navigationInterface
}) => {
  const fallbackEvent = fallbackInterface.eventName;
  const criterionEvent = criterionInterface.eventName;

  // TODO: create this event inside onboarding code
  const onboardingEvent = eventName + '-onboarding';
  // TODO: create this event inside navigation code
  const urlEvent = eventName + '-url';

  storeInterface.addObserver(
    storeInterface.generateId(),
    (state, previousStage) => {
      if (
        JSON.stringify(state?.onboarding) !==
        JSON.stringify(previousStage?.onboarding)
      ) {
        eventService.publish(onboardingEvent, undefined);
      }
    }
  );

  navigationInterface.listen(() => {
    eventService.publish(urlEvent, undefined);
  });

  return [fallbackEvent, criterionEvent, onboardingEvent, urlEvent];
};

export default function initializeMicrofrontendRouterInterface(options: {
  interfaces: {
    store: Store;
    navigation: NavigationInterfaceType;
    fallback: Fallback;
    layout: Layout;
    criterion: Criterion<any>;
    userSession: UserSessionInterfaceType;
    authProvider: PromiseReturnType<typeof authProvider>;
    localization: PromiseReturnType<typeof localization>;
    sessionService: ISessionService;
    tenantHandler: ITenantHandlerService;
    monitoringService: IMonitoringService;
    userService: IUserService;
  };
  stack: Stack;
  localization: LocalizationServiceInputType;
  onboarding: OnboardingInputType;
}): MicrofrontendRouterInterface {
  if (microfrontendRouterInterface) return microfrontendRouterInterface;

  const { navigationService, eventService } = getServices();

  const storeInterface = options.interfaces.store;
  const navigationInterface = options.interfaces.navigation;
  const fallbackInterface = options.interfaces.fallback;
  const layoutInterface = options.interfaces.layout;
  const criterionInterface = options.interfaces.criterion;
  const userSessionInterface = options.interfaces.userSession;
  const authProviderInterface = options.interfaces.authProvider;
  const localizationInterface = options.interfaces.localization;
  const tenantHandlerService = options.interfaces.tenantHandler;
  const sessionService = options.interfaces.sessionService;
  const monitoringService = options.interfaces.monitoringService;
  const eventName = 'shell-global-microfrontend-router';

  const eventNames = createRelatedEvents({
    eventName,
    fallbackInterface,
    criterionInterface,
    storeInterface,
    eventService,
    navigationInterface
  });

  microfrontendRouterInterface = new MicrofrontendRouterInterface({
    event: {
      eventService,
      eventList: [
        ...eventNames,
        'onboardingAgentFailedToLaunch',
        WebServiceRoutingEvents.ServiceInstanceClosed,
        WebServiceRoutingEvents.ServiceInstanceLaunching
      ],
      eventName
    },
    criterionInterface,
    fallbackInterface,
    layoutInterface,
    navigationInterface,
    monitoringService,
    tenantHandlerService,
    operations: [
      new FallbackOperation({
        fallbackInterface
      }),
      new LoginOperation({
        navigationInterface
      }),
      new ServiceRoutingOperation({
        userSessionInterface
      }),
      new OnboardingAgentOperation({
        globalPreloadAssetReferenceLocale:
          options?.localization?.preloadAssetReferenceLocale,
        resumeOnTriggers: !!options?.onboarding?.resumeOnTriggers,
        authProviderInterface,
        navigationInterface,
        stack: options.stack,
        storeInterface,
        onboardingTriggerList: options?.onboarding?.triggers,
        userSessionInterface,
        onboardingDirectorClientId: options?.onboarding?.clientId,
        localizationInterface: localizationInterface,
        fallbackInterface,
        sessionService,
        tenantHandlerService: tenantHandlerService,
        monitoringService,
        navigationService
      }),
      new PathRouteOperation({
        globalPreloadAssetReferenceLocale:
          options?.localization?.preloadAssetReferenceLocale,
        userSessionInterface,
        fallbackInterface
      })
    ]
  });

  return microfrontendRouterInterface;
}
