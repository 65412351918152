import { FilterCDMTreesResult } from '@jarvis/jweb-core';
import jsonpath from 'jsonpath';
import { Schema, Validator } from 'jsonschema';
import { glossary } from '../schemas/com.hp.cdm.domain.glossary.version.1.schema';
import { governanceTags } from '../schemas/com.hp.cdm.domain.privacy.type.governanceTags.version.1.schema';
import { originatorContextDetail } from '../schemas/com.hp.cdm.domain.telemetry.type.originatorContextDetail.originator.ucdeCloudWebApp.version.1.schema';
import { originatorDetail } from '../schemas/com.hp.cdm.domain.telemetry.type.originatorDetail.originator.ucdeCloudWebApp.version.1.schema';
import { dataValve } from '../schemas/com.hp.cdm.service.dataValve.version.1.sharedTypes.dataValve.schema';
import { eventingEvent } from '../schemas/com.hp.cdm.service.eventing.version.1.resource.eventingEvent.schema';
import { notificationSchema } from '../schemas/com.hp.cdm.service.eventing.version.1.resource.notification.schema';
import { subscription } from '../schemas/com.hp.cdm.service.eventing.version.1.resource.subscription.schema';
import { eventing } from '../schemas/com.hp.cdm.service.eventing.version.1.sharedTypes.eventing.schema';

export const validateSchema = (filteredNotification: FilterCDMTreesResult) => {
  const validator = new Validator();
  jsonpath.apply(
    eventing,
    '$.definitions.eventCategory',
    () => JSON.parse(
      removeSchemaKey(JSON.stringify(eventing?.definitions?.eventCategory), 'enum')
    )
  );
  const schemas = [
    notificationSchema,
    glossary,
    eventingEvent,
    eventing,
    dataValve,
    subscription,
    originatorDetail,
    originatorContextDetail,
    governanceTags
  ];

  schemas.forEach(schema => {
    validator.addSchema(schema, schema.id);
  });

  const notificationschema = schemas.find(schema => schema.id === 'https://schemaregistry.analysis.ext.hp.com/cdm/gun/com.hp.cdm.service.eventing.version.1.resource.notification.schema.json');
  const isValidSchema = validator.validate(filteredNotification, notificationschema as Schema);

  return isValidSchema;

};

const removeSchemaKey = (schemaString: string, keyToRemove: string) => {
  const schema = JSON.parse(schemaString);
  delete schema[keyToRemove];
  return JSON.stringify(schema);
};

