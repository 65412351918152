import { CDMObject, DataCollectionEventAction, DataCollectionEventResult, EventFailureReason, FilterCDMTreesResult, FilterNotificationResult, ValveFilterError } from '@jarvis/jweb-core';
import { EventDB } from '../DB/IndexedDB';
import { APIKeyConfiguration, AuthProviderConfiguration, QueueDefaults } from '../dataCollectionService/dataCollectionServiceTypes';
import { logger } from '../helpers/logger';
import { TelemetryClient } from '../client/TelemetryClient/TelemetryClient';
import { validateSchema } from '../helpers/schemaValidation';
import { publishResultEventData } from '../helpers/publishResultEventData';
import { dataCollectionService } from '../dataCollectionService/dataCollectionService';
import { getWindowValues } from '../client/utils/enum';
import { QueueItem } from './QueueItem';
import { QueueWorker, filterNotification } from './QueueWorker';
import { QueueItemStatus, publishFilterError, publishTelemetryError } from './queueHelpers';

export class Queue {
  static publishRetries = QueueDefaults.PUBLISH_RETRIES;
  static publishRetryDelay = QueueDefaults.PUBLISH_RETRY_DELAY;
  static queueItemTTLInHours = QueueDefaults.QUEUE_ITEM_TTL_IN_HOURS;
  static queueSizeLimit = QueueDefaults.QUEUE_SIZE_LIMIT;
  static incomingDataQueue: QueueItem[] = [];
  static enqueing = false;
  static paused = false;

  static peek = async () => {
    const element = await EventDB.getElement();
    return element;
  };

  static enqueue = (item: QueueItem) => {
    Queue.incomingDataQueue.push(item);
    Queue.addToQueue();
  };

  static addToQueue = async () => {
    if (!Queue.queueSizeLimit || Queue.enqueing) return;
    Queue.enqueing = true;
    while (Queue.incomingDataQueue.length) {
      const element: QueueItem | undefined = Queue.incomingDataQueue.shift();
      const preBuilt = element?.preBuilt;
      if (element !== undefined) {
        let filterRequired = false;
        const trackingIdentifiers = element.trackingIdentifiers;
        try {
          for (const eventObject of element.notification.events) {
            if (eventObject?.filter === undefined && eventObject?.filterError === undefined) {
              filterRequired = true;
              break;
            }
          }
        } catch (err: any) {
          await publishResultEventData(DataCollectionEventAction.finish, trackingIdentifiers, { result: DataCollectionEventResult.failure, message: err.message });
          logger.log(err.message);
        }
        const count = await Queue.count();
        if (EventDB.db && indexedDB) {
          if (count >= Queue.queueSizeLimit) {
            const itemToBeRemoved: unknown = await Queue.peek();
            await Queue.removeById((itemToBeRemoved as IDBCursorWithValue)?.key as string);
          }
          const webAppConsent = getWindowValues().sessionStorage.getItem('webAppConsent');
          const preConsentEventAccumulation = dataCollectionService.getConfiguration()?.preConsentEventAccumulation;
          const isBatchingEnabled = dataCollectionService.getConfiguration()?.isBatchingEnabled;
          if (!QueueWorker.running && (!preConsentEventAccumulation || (webAppConsent !== 'undefined' && webAppConsent)) && isBatchingEnabled === true) {
            logger.log('Queue:addToQueue:startSendData: isBatchEnabled',isBatchingEnabled);
            QueueWorker.startSendData();
          }
          if (isBatchingEnabled === false || preBuilt === true) {
            logger.log(`Queue::addToQueue::isBatchingEnabled:${isBatchingEnabled}:preBuilt:${preBuilt}`);
            await EventDB.add(element);
          }
        } else {
          const configuration = dataCollectionService.getConfiguration();
          if (configuration) {
            logger.warn('Queue::addToQueue::warn:db is not initialized and skipping queueing');
            let responseTime: number | undefined;
            let beginTime: number | undefined;
            let endTime: number;
            try {
              let filteredData: FilterCDMTreesResult | undefined = {
                results: [{ tree: '', treeGun: '' }]
              };
              if (filterRequired) {
                for (const eventObject of element.notification.events) {
                  eventObject?.filter !== undefined && delete eventObject['filter'];
                  eventObject?.filterError !== undefined && delete eventObject['filterError'];
                }
                filteredData = await filterNotification(element);
                if ((filteredData?.results[0] as ValveFilterError)?.errorType) {
                  // publish the filtererror
                  await publishFilterError(element.metadata, filteredData, trackingIdentifiers);
                  logger.log('Queue::addToQueue:Got ValveFilterError!');
                  continue;
                }
                await publishResultEventData(DataCollectionEventAction.filterNotification, trackingIdentifiers, { valveControllerMetadata: element.metadata, valveFilterResult: filteredData?.results } as FilterNotificationResult);
              } else {
                // for prebuilt Notification
                const cdmObject: CDMObject = {
                  tree: JSON.stringify(element.notification),
                  treeGun: 'com.hp.cdm.service.eventing.version.1.resource.notification'
                };
                await publishResultEventData(
                  DataCollectionEventAction.filterNotification,
                  trackingIdentifiers,
                  {
                    valveControllerMetadata: element.metadata,
                    valveFilterResult: 'Skipped due to preexisting filter operation'
                  } as FilterNotificationResult);
                filteredData.results = [cdmObject];
              }
              const filteredNotification = JSON.parse((filteredData?.results as [CDMObject])[0].tree);
              const client = new TelemetryClient(configuration.stack, element.applicationContext, (configuration as AuthProviderConfiguration).authProvider, (configuration as APIKeyConfiguration).telemetryAPIkey);

              // Schema validation for Envelope
              const validationResult = validateSchema(filteredNotification);
              if (!validationResult.valid) {
                await publishResultEventData(DataCollectionEventAction.publishNotification, trackingIdentifiers, { telemetryServiceResponse: { reason: validationResult.errors.join(', ') } });
                logger.log('Queue::addToQueue::Schema Validation failed for Envelope:', validationResult.propertyPath, validationResult.errors.join().toString());
                await publishResultEventData(DataCollectionEventAction.finish, trackingIdentifiers, { result: DataCollectionEventResult.failure, message: `${EventFailureReason.localSchemaValidationFailure}:${validationResult.errors.join()}` });
                continue;
              }
              // Send event to telementry client
              logger.log('Queue::addToQueue:Trying to send the event');
              beginTime = Date.now();
              const response = await client.sendEvent(filteredNotification, trackingIdentifiers);
              endTime = Date.now();
              responseTime = endTime - beginTime;
              await publishResultEventData(DataCollectionEventAction.publishNotification, trackingIdentifiers, undefined, response, responseTime);
              await publishResultEventData(DataCollectionEventAction.finish, trackingIdentifiers, { result: (response.status === 206 ? DataCollectionEventResult.partialSuccess : DataCollectionEventResult.success), message: response.data });
            } catch (err: any) {
              logger.log('Queue::addToQueue::error:', err.message);
              await publishTelemetryError(err, trackingIdentifiers, beginTime);
            }
          }
        }
      } else {
        logger.log('Queue::addToQueue::Queue is undefined');
      }
    }
    Queue.enqueing = false;
  };

  static removeById = async (id: string) => {
    await EventDB.removeById(id);
  };

  static count = async () => {
    const length = await EventDB.count();
    return length;

  };

  static update = async (item: object, key: number) => {
    logger.log('Queue::addToQueue::update:updating element');
    await EventDB.update(item, key);
  };

  static getProcessingItem = async () => {
    const pendingItem = await EventDB.getProcessElementFromDB();
    return pendingItem;
  };

  static getAllPendingItems = async (status: QueueItemStatus) => {
    const allItems = await EventDB.getQueueItems(status);
    return allItems;
  };
}
