export function shouldPreloadAssetReferenceLocale({
  globalPreload,
  localPreload
}: {
  globalPreload?: boolean;
  localPreload?: boolean;
}): boolean {
  if (localPreload === false) return false;

  if (localPreload === undefined) {
    // now it uses global configuration
    return !!globalPreload;
  }
  return localPreload;
}
