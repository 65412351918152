import bindAllMethods from '../../../utils/bindAllMethods';
import { IFeatureFlagService } from '../../../services/featureFlagService';
import FeatureFlagClientInterfaceV1 from './client/FeatureFlagClientInterfaceV1';
import * as T from './types';

class FeatureFlagInterfaceV1 {
  private _featureFlagService: IFeatureFlagService;
  private _featureFlagClients: {
    key: string;
    client: FeatureFlagClientInterfaceV1;
  }[];

  constructor({ featureFlagService }: T.FeatureFlagInterfaceV1Param) {
    this._featureFlagService = featureFlagService;
    this._featureFlagClients = [];
    bindAllMethods(this);
  }

  public getInterface(): T.FeatureFlagInterfaceV1Type {
    return {
      getClient: (key) => this._getClient(key),
      useReactFeatureFlag: this?._featureFlagService?.useReactFeatureFlag
    };
  }

  private async _getClient(
    key: string
  ): Promise<T.FeatureFlagClientInterfaceV1Type> {
    let client = this._featureFlagClients.find((c) => c.key === key);
    if (!client) {
      client = { client: undefined, key: undefined };
      const featureFlagClient = await this._featureFlagService?.getClient(key);
      const interfaceClient = new FeatureFlagClientInterfaceV1({
        featureFlagClient
      });
      client.client = interfaceClient;
      client.key = key;
      this._featureFlagClients.push({ key, client: interfaceClient });
    }
    return client.client.getInterface();
  }
}

export default FeatureFlagInterfaceV1;
