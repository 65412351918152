import EventNames from '../../../config/eventNames';
import { JWebErrorHandler } from '../../JWeb';
import { SubscriptionHandle } from '../../JWeb/types';
import * as T from '../types';
import isObject from '../../../utils/isObject';
import IEventService from '../IEventService';
import { nativeEventPublisherPromise, nativeSubscriberPromise } from './utils';
import bindAllMethods from '../../../utils/bindAllMethods';
import { singleton } from 'tsyringe';
import { publisherId, getMap } from '../utils';
import BaseEventService from '../BaseEventService';

@singleton()
class NativeEventService extends BaseEventService implements IEventService {
  public eventNames = EventNames;

  constructor() {
    super();
    bindAllMethods(this);
  }

  async subscribe(
    eventName: string,
    action: T.EventServiceCallBackType,
    options?: T.EventServiceSubscribeOptionsType
  ): Promise<T.ListenerHandlerType> {
    const nativeSubscriber = await nativeSubscriberPromise;

    const nativeListenerHandler = JWebErrorHandler<SubscriptionHandle>(
      await nativeSubscriber?.subscribe(
        { eventName, publisherId: options?.publisherId },
        ({ eventData, publisherId: nativePublisherId }) => {
          action({
            eventData,
            eventName,
            publisherId: nativePublisherId || publisherId
          });
        }
      )
    );

    return {
      unsubscribe: async () => {
        await nativeListenerHandler.unsubscribe();
      }
    };
  }

  async publish(
    eventName: string,
    eventData: T.EventServiceValueType['eventData']
  ): Promise<void> {
    const sanitizedEventData = isObject(eventData) ? eventData : {};
    const nativeEventPublisher = await nativeEventPublisherPromise;

    await nativeEventPublisher?.publish(eventName, sanitizedEventData);
  }
}

export default NativeEventService;
