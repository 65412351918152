import { getSessionData } from '../../../../../clients/shell/session';
import MicrofrontendRouterOperationStateBuilder from '../../builder/MicrofrontendRouterOperationStateBuilder';
import * as T from './types';
import WebServiceRouting from '../../../../../services/webServiceRouting';
import { getServices } from '../../../../../infra/commonInitializer';
import getLocalCachedOnboardingAgentSession from '../OnboardingAgentOperation/OnboardingAgent/utils/getLocalCachedOnboardingAgentSession';
import { ServiceInstanceState } from '../../../../../services/JWeb/JWebEnums';

export default class ServiceRoutingOperation
  implements T.IMicrofrontendRouterOperation
{
  private userSessionInterface: T.ServiceRoutingOperationDependenciesType['userSessionInterface'];
  private _webServiceRouting: WebServiceRouting;

  constructor(dependencies: T.ServiceRoutingOperationDependenciesType) {
    const services = getServices();
    this._webServiceRouting = services.webServiceRouting;
    this.userSessionInterface = dependencies.userSessionInterface;
  }

  private async getServiceInstance() {
    return this._webServiceRouting.separateErrorObject(
      await this._webServiceRouting.getServiceInstance()
    )?.data;
  }

  private async getService(serviceId: string) {
    if (!serviceId) return undefined;
    return this._webServiceRouting.separateErrorObject(
      await this._webServiceRouting.getServiceAvailability({ serviceId })
    )?.data;
  }

  private async isLoginRequired(options: {
    assetReference: string;
    public?: boolean;
  }) {
    if (options?.assetReference && !options?.public) {
      const session = await getSessionData();

      return !session?.isLoggedIn;
    }
    return false;
  }

  async process(
    stateBuilder: MicrofrontendRouterOperationStateBuilder
  ): Promise<void> {
    const serviceInstance = await this.getServiceInstance();

    // Case #1: no state was provided
    if (
      serviceInstance &&
      serviceInstance?.state !== ServiceInstanceState.closed
    ) {
      const service = await this.getService(serviceInstance?.serviceId);
      const tenantHandlerOverride =
        getLocalCachedOnboardingAgentSession()?.trigger?.tenantHandlerOverride;

      if (service.serviceType === 'web') {
        const isLoginRequired = await this.isLoginRequired({
          assetReference: service?.assetReference,
          public: service?.public
        });

        if (isLoginRequired) {
          const loginPath = this.userSessionInterface.getLoginPath();

          stateBuilder.setRedirectTo(loginPath);
        } else {
          /**
           * Load content only if state is running
           */
          if (serviceInstance?.state === ServiceInstanceState.running) {
            stateBuilder.setContent({
              enable: !!service?.assetReference,
              assetReference: service?.assetReference,
              criterionKey: service?.criterionKey,
              properties: service?.properties,
              key: service?.id,
              tenantHandlerOverride: tenantHandlerOverride,
              skeletonReference: service?.skeletonReference
            });
          }
        }
      }
    }
  }
}
