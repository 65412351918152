import { shouldPreloadAssetReferenceLocale } from '../../interface/v1/MicrofrontendRouter/operations/shouldPreloadAssetReferenceLocale';
import bindAllMethods from '../../utils/bindAllMethods';
import ILayoutsService, * as T from './ILayoutsService';

export default class LayoutsService implements ILayoutsService {
  private _globalPreloadAssetReferenceLocale = false;
  private _layoutList: T.LayoutsServiceConstructorOptionsType['layoutList'];
  defaultLayout: T.AssetPropertyType;

  constructor(options: T.LayoutsServiceConstructorOptionsType) {
    bindAllMethods(this);
    this._layoutList = options?.layoutList;

    if (options?.defaultLayoutKey) {
      this.defaultLayout = this.getLayoutByKey(options?.defaultLayoutKey);
    }
  }

  public setDependencies(
    options: T.LayoutServiceSetDependenciesPropsType
  ): void {
    this._globalPreloadAssetReferenceLocale =
      !!options?.services?.localization?.isPreloadAssetReferenceLocale?.();
  }

  public getLayoutByKey(key: string): T.AssetPropertyType {
    const currentLayout = this._layoutList?.find?.(
      (fallbackDescription) => fallbackDescription?.key === key
    );
    const willPreloadLocale = shouldPreloadAssetReferenceLocale({
      globalPreload: this._globalPreloadAssetReferenceLocale,
      localPreload: currentLayout?.preloadAssetReferenceLocale
    });
    if (typeof currentLayout === 'object') {
      currentLayout.preloadAssetReferenceLocale = willPreloadLocale;
    }

    return currentLayout;
  }
}
