import bindAllMethods from '../../../../utils/bindAllMethods';
import { IFeatureFlagClient } from '../../../../services/featureFlagService';
import { FeatureFlagClientInterfaceV1Type } from '../types';

type FeatureFlagClientInterfaceV1Param = {
  featureFlagClient: IFeatureFlagClient;
};

class FeatureFlagClientInterfaceV1 {
  private _featureFlagClient: IFeatureFlagClient;

  constructor({ featureFlagClient }: FeatureFlagClientInterfaceV1Param) {
    this._featureFlagClient = featureFlagClient;
    bindAllMethods(this);
  }

  public getInterface(): FeatureFlagClientInterfaceV1Type {
    return {
      getFeatureFlag: this?._featureFlagClient?.getFeatureFlag
    };
  }
}

export default FeatureFlagClientInterfaceV1;
