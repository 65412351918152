import TenantObserver, {
  TenantEvents
} from '../../services/tenantHandler/TenantObserver';
import {
  HandledTenant,
  InitialTenantType
} from '../../services/tenantHandler/types';
import { ISessionService } from '../../services/session';
import ShellSessionStorageDAOImpl from '../../dao/ShellSessionStorageDAOImpl';
import { LastTenantIdsRepository } from './KeepLatestTenantOnNewTabs/LastTenantIdsRepository';
import { INavigationService } from '../../services/navigationService';
import ITenantHandlerService from '../../services/tenantHandler/ITenantHandlerService';

export type UseTenantIdsFromQueryParamsType = {
  tenantHandlerService: ITenantHandlerService;
  navigationService: INavigationService;
  sessionService: ISessionService;
};

export default class UseTenantIdsFromQueryParams {
  private DEPRECATED_INITIAL_TENANT_CONSTANT = 'organizationId';
  private _tenantHandlerService: ITenantHandlerService;
  private _navigation: INavigationService;
  private _sessionService: ISessionService;
  private _lastTenantIdsRepository: LastTenantIdsRepository;
  private _navigationService: INavigationService;

  constructor({
    tenantHandlerService,
    navigationService,
    sessionService
  }: UseTenantIdsFromQueryParamsType) {
    this._tenantHandlerService = tenantHandlerService;
    this._sessionService = sessionService;

    const shellDAO = new ShellSessionStorageDAOImpl();
    this._lastTenantIdsRepository = new LastTenantIdsRepository({ shellDAO });

    this._navigation = navigationService;
    this._navigationService = navigationService;
  }

  public async init(): Promise<void> {
    const isLoggedIn = this._sessionService.isLoggedIn();
    !isLoggedIn && this._lastTenantIdsRepository?.clear?.();
    if (!isLoggedIn && this._hasTenantOnQueryParams()) {
      const tenantIdList = this._createTenantListFromQueryParams();
      this._lastTenantIdsRepository.save(tenantIdList);
    }

    if (!isLoggedIn || !this._tenantHandlerService?.isEnabled()) {
      return;
    }

    // Navigation decorators
    this._persistTenantQueryParam();

    // Recovery session
    this._useStoragedLoggedOutTenantIdsQueryParams();

    // TenantHandler behaviors
    this._hasTenantOnQueryParams() &&
      this._tenantHandlerService.setInitialTenants(
        this._createTenantListFromQueryParams()
      );

    TenantObserver.subscribe(
      TenantEvents.SET_TENANT,
      this.setTenantOnNavigation
    );

    TenantObserver.subscribe(
      TenantEvents.SET_TENANT_HANDLER_KEY,
      this._setTenantsFromQueryParams
    );
  }

  private _useStoragedLoggedOutTenantIdsQueryParams(): void {
    const tenantIdList = this._lastTenantIdsRepository.find();
    if (tenantIdList?.length) {
      const urlSearchParams = new URLSearchParams(
        this._navigation.location.search
      );

      tenantIdList.forEach((t) => {
        const tenantQueryParam = `t${t.level}`;
        urlSearchParams.set(tenantQueryParam, t.id);
      });

      this._navigation.replace({
        ...this._navigation.location,
        search: urlSearchParams.toString()
      });
    }
  }

  private _hasTenantOnQueryParams(): boolean {
    const queryParams = new URLSearchParams(window.location.search);
    // TODO: Remove this after Instant Ink stops to use it. It's to support a deprecated feature.
    const hasTenantOnDeprecatedQueryParam = queryParams.has(
      this.DEPRECATED_INITIAL_TENANT_CONSTANT
    );

    return (
      queryParams.has(`t${this._tenantHandlerService.START_TENANT_LEVEL}`) ||
      hasTenantOnDeprecatedQueryParam
    );
  }

  private _rehydrateQueryParams(): void {
    if (this._hasTenantOnQueryParams()) return;
    else if (this._tenantHandlerService?.getInitialTenants?.()?.length) {
      this._navigation.replace(this._navigation.location);
    }
  }

  private _setTenantsFromQueryParams = async (
    tenants: HandledTenant[]
  ): Promise<void> => {
    const tenantList = this._hasTenantOnQueryParams()
      ? this._createTenantListFromQueryParams()
      : this._tenantHandlerService?.getInitialTenants();
    if (!tenantList?.length) return;
    const options = { reload: false };

    const tenantIdList = [] as string[];

    for (const tenant of tenantList) {
      tenantIdList.push(tenant.id);
      const tenantSuffix = tenantIdList.join(
        this._tenantHandlerService.TENANT_SUFFIX_CONCAT_STRING
      );
      if (
        !this._tenantHandlerService?.checkIfTenantIsStoredBySuffix(tenantSuffix)
      ) {
        await this._tenantHandlerService?.setTenant(
          tenant.id,
          tenant.level,
          options,
          undefined
        );
      }
    }
    this._rehydrateQueryParams();
  };

  private setTenantOnNavigation = (tenants: HandledTenant[]): void => {
    this._lastTenantIdsRepository?.clear?.();
    const urlSearchParams = new URLSearchParams(window.location.search);
    tenants.forEach((t) => {
      const tenantQueryParam = `t${t.level}`;
      if (t.proccessed) urlSearchParams.set(tenantQueryParam, t.id);
      else urlSearchParams.delete(tenantQueryParam);
    });
    this._navigation.replace({
      ...this._navigation.location,
      search: urlSearchParams.toString()
    });
  };

  private _persistTenantQueryParam() {
    this._navigationService.setPersistQueryParam(
      'tenantQueryParams',
      (queryParamsObject) => {
        const newQueryParams = { ...queryParamsObject };
        let lastTenantLevel = 0;

        this?._tenantHandlerService?.getHandledTenantList().forEach((t) => {
          if (t.proccessed) {
            lastTenantLevel =
              t.level > lastTenantLevel ? t.level : lastTenantLevel;
            newQueryParams[`t${t.level}`] = t.id;
          }
        });

        for (const key in newQueryParams) {
          const isTenantKey = new RegExp('^t[0-9]+$').test(key);

          if (isTenantKey) {
            const tenantNumber = Number(
              key?.split?.('t')?.filter?.(Boolean)?.[0]
            );

            if (tenantNumber > lastTenantLevel) {
              newQueryParams[key] = undefined;
            }
          }
        }

        return newQueryParams;
      }
    );
  }

  private _createTenantListFromQueryParams(): InitialTenantType[] {
    const queryParams = new URLSearchParams(this._navigation.location.search);
    let tenantLevel = this._tenantHandlerService?.START_TENANT_LEVEL;
    const tenantIdList = [] as InitialTenantType[];

    // TODO: Remove this after Instant Ink stops to use it. It's to support a deprecated feature.
    const deprecatedInitialTenant = queryParams.get(
      this.DEPRECATED_INITIAL_TENANT_CONSTANT
    );
    if (deprecatedInitialTenant) {
      tenantIdList.push({
        level: tenantLevel,
        id: deprecatedInitialTenant
      });
    } else {
      while (queryParams.has(`t${tenantLevel}`)) {
        const tenantQuery = `t${tenantLevel}`;
        const tenantId = queryParams.get(tenantQuery);
        tenantIdList.push({ level: tenantLevel, id: tenantId || '' });
        tenantLevel++;
      }
    }
    return tenantIdList;
  }
}
