import TenantObserver, {
  TenantEvents
} from '../../../services/tenantHandler/TenantObserver';
import { TenantHandlerService } from '../../../services/tenantHandler';
import { ISessionService } from '../../../services/session';
import { HandledTenant } from '../../../services/tenantHandler/types';
import { LastTenantIdsRepository } from './LastTenantIdsRepository';

export default class KeepLatestTenantOnNewTabs {
  private _tenantHandler: TenantHandlerService;
  private _sessionService: ISessionService;
  private _repository: LastTenantIdsRepository;

  constructor({ tenantHandler, sessionService, repository }) {
    this._tenantHandler = tenantHandler;
    this._sessionService = sessionService;
    this._repository = repository;
  }
  public async init(): Promise<void> {
    const isLoggedIn = this._sessionService.isLoggedIn();
    if (!isLoggedIn || !this._tenantHandler?.isEnabled()) {
      this._repository.clear();
      return;
    }

    this.setInitialTenantBasedOnLastTenant();

    TenantObserver.subscribe(TenantEvents.SET_TENANT, this.updateLastTenant);
  }

  private setInitialTenantBasedOnLastTenant() {
    if (!this._tenantHandler?.getInitialTenants?.()?.length) {
      const lastTenantIds = this._repository.find()?.map((t) => {
        return {
          level: t.level,
          id: t.id
        };
      });
      lastTenantIds?.length &&
        this._tenantHandler?.setInitialTenants(lastTenantIds);
    }
  }

  private updateLastTenant = (tenants: HandledTenant[]): void => {
    const initialTenants = [];
    tenants.forEach((t) => {
      if (t.proccessed) initialTenants.push({ level: t.level, id: t.id });
    });
    this._repository.save(initialTenants);
  };
}
