import { SetServiceDependencies } from '../../infra/commonInitializer/types';
import { IAuthProviderService } from '../authProviderService';
import IAuthTokenService from '../authTokenService/IAuthTokenService';
import { IGrantService } from './IGrantService';
import IApplicationService from '../applicationService/IApplicationService';
import { GrantOptionsType, GrantType } from './types';
import { GrantRepository } from './GrantRepository';
import { AuthContextEnum } from '../authTokenService';
import IGrantsClient from '../../clients/stratus/grants/IGrantsClient';
import { PendingGrantRepository } from './PendingGrantRepository';
import { GrantsInputType } from './types';

export class GrantService implements IGrantService {
  private _authTokenService: IAuthTokenService;
  private _authProviderService: IAuthProviderService;
  private _applicationService: IApplicationService;
  private _grantRepository: GrantRepository;
  private _pendingGrantRepository: PendingGrantRepository;
  private _grantClient: IGrantsClient;
  private _enabled: boolean;

  constructor(props?: GrantsInputType) {
    this._enabled = props?.enabled || false;
  }

  public setDependencies({
    services,
    repositories,
    clients
  }: SetServiceDependencies): void {
    const { authProviderService, authTokenService, applicationService } =
      services;
    const { grantRepository } = repositories;
    const { stratus } = clients;

    this._authProviderService = authProviderService;
    this._authTokenService = authTokenService;
    this._applicationService = applicationService;
    this._grantRepository = grantRepository;
    this._grantClient = stratus.grantClient;
    this._pendingGrantRepository = repositories.pendingGrantRepository;
  }

  private shouldUpdateCache(
    srcGrants: GrantType[],
    targetGrants: GrantType[]
  ): boolean {
    return JSON.stringify(srcGrants) !== JSON.stringify(targetGrants);
  }

  private getGrantsFromCache(authContext: AuthContextEnum): GrantType[] {
    return this._grantRepository.findOne(
      this._authTokenService.getSuffix(authContext)
    );
  }

  private getPendingGrantsFromCache(authContext: AuthContextEnum): GrantType[] {
    return this._pendingGrantRepository.findOne(
      this._authTokenService.getSuffix(authContext)
    );
  }

  private saveGrantsToCache(
    grants: GrantType[],
    authContext: AuthContextEnum
  ): void {
    this._grantRepository.save(
      this._authTokenService.getSuffix(authContext),
      grants
    );
  }

  private savePendingGrantsToCache(
    grants: GrantType[],
    authContext: AuthContextEnum
  ): void {
    this._pendingGrantRepository.save(
      this._authTokenService.getSuffix(authContext),
      grants
    );
  }

  private async getGrantsFromClient(
    authContext: AuthContextEnum
  ): Promise<GrantType[]> {
    const grants = await this._grantClient.getGrants({
      stack: this._applicationService.getAuthStack(),
      authProvider:
        this._authProviderService.createAuthProviderByAuthContextEnum(
          authContext
        )
    });

    return grants?.contents?.map((c) => ({ grant: c.grant, level: c.level }));
  }

  private async getPendingGrantsFromClient(
    authContext: AuthContextEnum
  ): Promise<GrantType[]> {
    const grants = await this._grantClient.getPendingGrants({
      stack: this._applicationService.getAuthStack(),
      authProvider:
        this._authProviderService.createAuthProviderByAuthContextEnum(
          authContext
        )
    });

    return grants?.contents?.map((c) => ({ grant: c.grant, level: c.level }));
  }

  public async checkGrants(
    grants: GrantType[],
    options?: GrantOptionsType
  ): Promise<boolean> {
    if (!this.isEnabled()) return true;

    const authContext =
      options?.authContext || this._authTokenService.getCurrentContext();

    const contents = this.getGrantsFromCache(authContext);

    return grants?.every?.((grantToCheck) =>
      contents?.some?.((cachedGrant: GrantType) => {
        const hasLevel = !!grantToCheck?.level;
        const levelTest = hasLevel
          ? grantToCheck.level === cachedGrant.level
          : true;

        return grantToCheck.grant === cachedGrant.grant && levelTest;
      })
    );
  }

  public async checkPendingGrants(
    pendingGrants: GrantType[],
    options?: GrantOptionsType
  ): Promise<boolean> {
    if (!this.isEnabled()) return true;

    const authContext =
      options?.authContext || this._authTokenService.getCurrentContext();

    const contents = this.getPendingGrantsFromCache(authContext);

    return pendingGrants?.every?.((pendingGrantToCheck) =>
      contents?.some?.((cachedPendingGrant: GrantType) => {
        const hasLevel = !!pendingGrantToCheck?.level;
        const levelTest = hasLevel
          ? pendingGrantToCheck.level === cachedPendingGrant.level
          : true;

        return (
          pendingGrantToCheck.grant === cachedPendingGrant.grant && levelTest
        );
      })
    );
  }

  public async haveGrantsChanged(options?: GrantOptionsType): Promise<boolean> {
    if (!this.isEnabled()) return false;

    const authContext =
      options?.authContext || this._authTokenService.getCurrentContext();

    const grants = await this.getGrantsFromClient(authContext);
    const cachedGrants = this.getGrantsFromCache(authContext);

    if (this.shouldUpdateCache(grants, cachedGrants)) {
      this.saveGrantsToCache(grants, authContext);
      return true;
    }

    return false;
  }

  public async havePendingGrantsChanged(
    options?: GrantOptionsType
  ): Promise<boolean> {
    if (!this.isEnabled()) return false;

    const authContext =
      options?.authContext || this._authTokenService.getCurrentContext();

    const pendingGrants = await this.getPendingGrantsFromClient(authContext);
    const cachedPendingGrants = this.getPendingGrantsFromCache(authContext);

    if (this.shouldUpdateCache(pendingGrants, cachedPendingGrants)) {
      this.savePendingGrantsToCache(pendingGrants, authContext);
      return true;
    }

    return false;
  }

  public async getGrant(
    grant: GrantType,
    options?: GrantOptionsType
  ): Promise<GrantType> {
    if (!this.isEnabled()) return undefined;

    const authContext =
      options?.authContext || this._authTokenService.getCurrentContext();

    const grantsFromCache = this.getGrantsFromCache(authContext);

    return grantsFromCache.find(
      (grantFromCache) =>
        grantFromCache.grant === grant.grant &&
        grantFromCache.level === grant.level
    );
  }

  public async getPendingGrant(
    pendingGrant: GrantType,
    options?: GrantOptionsType
  ): Promise<GrantType> {
    if (!this.isEnabled()) return undefined;

    const authContext =
      options?.authContext || this._authTokenService.getCurrentContext();

    const pendingGrantsFromCache = this.getPendingGrantsFromCache(authContext);

    return pendingGrantsFromCache.find(
      (pendingGrantFromCache) =>
        pendingGrantFromCache.grant === pendingGrant.grant &&
        pendingGrantFromCache.level === pendingGrant.level
    );
  }

  public isEnabled(): boolean {
    if (!this._enabled) {
      console.warn('Grants: disabled');
      return false;
    }
    return true;
  }
}
