import bindAllMethods from '../../../utils/bindAllMethods';
import AuthContextEnum from '../../authTokenService/AuthContextEnum';
import type IRefreshTokenService from './IRefreshTokenService';
import { RefreshParameterType } from '../ISessionService';
import { Lifecycle, inject, injectable, scoped } from 'tsyringe';
import { internalLogger } from '../../../interface/v1/logger';
import { TenantStrategyEnum } from '../../tenantHandler/strategy/TenantStrategyEnum';
import IExchangeTokenService from '../exchangeTokenService/IExchangeTokenService';
import ExchangeTokenServiceNative from '../exchangeTokenService/ExchangeTokenServiceNative';
import { getJWeb } from '../../JWeb';
import * as JWebTypes from '../../JWeb/types';
import { TokenType } from '../../JWeb/JWebEnums';
import { AuthTokenService, IAuthTokenService } from '../../authTokenService';

@injectable()
@scoped(Lifecycle.ContainerScoped)
class RefreshTokenServiceNative implements IRefreshTokenService {
  private _refreshPromise: Promise<void>;
  private _authTokenService: IAuthTokenService;
  private _exchangeTokenServiceNative: IExchangeTokenService;

  constructor(
    @inject(AuthTokenService)
    authTokenService: IAuthTokenService,
    @inject(ExchangeTokenServiceNative)
    exchangeTokenServiceNative: IExchangeTokenService
  ) {
    this._authTokenService = authTokenService;
    this._exchangeTokenServiceNative = exchangeTokenServiceNative;
    bindAllMethods(this);
  }

  public async refresh(
    refreshParameterType?: RefreshParameterType
  ): Promise<void> {
    internalLogger.log('Refresh token', refreshParameterType?.tenantsIdMap);

    if (this._refreshPromise) return this._refreshPromise;

    const refreshAction = async () => {
      //TODO Fix why the setStratusAccessTokenFromNative is needed then remove this from here
      await this._setStratusAccessTokenFromNative();
      await this._exchangeTokenServiceNative.exchange(
        null,
        AuthContextEnum.tenant,
        TenantStrategyEnum.stratusNative
      );
    };

    this._refreshPromise = refreshAction().finally(() => {
      this._refreshPromise = undefined;
    });

    return this._refreshPromise;
  }

  private async _setStratusAccessTokenFromNative(): Promise<void> {
    try {
      const Auth = await getJWeb()?.then?.((res) => res?.Plugins?.Auth);
      const tokenProviderOptions = {
        tokenType: TokenType.user,
        requireFreshToken: true
      };
      const { tokenValue } = (await Auth?.getToken?.({
        tokenProviderOptions
      })) as JWebTypes.AccessToken;

      const haveAccessToken =
        typeof tokenValue === 'string' && tokenValue.length > 1;
      if (haveAccessToken) {
        this._authTokenService.setToken(tokenValue, AuthContextEnum.tenantless);
      } else {
        this._authTokenService.deleteToken(AuthContextEnum.tenantless);
      }
    } catch (error) {
      this._authTokenService.deleteToken(AuthContextEnum.tenantless);
      internalLogger.error('Error on getToken');
      console.error(error);
      return;
    }
  }
}

export default RefreshTokenServiceNative;
