import FeatureFlagInterfaceV1 from './FeatureFlagInterfaceV1';
import { IFeatureFlagService } from '../../../services/featureFlagService';
import {
  FeatureFlagInterfaceV1Type,
  FeatureFlagClientInterfaceV1Type
} from './types';

type CreateFeatureFlagInterfaceV1SingletonPropsType = {
  featureFlagService: IFeatureFlagService;
};

let featureFlagInterfaceV1: FeatureFlagInterfaceV1;

function _createFeatureFlagInterfaceV1(
  options: CreateFeatureFlagInterfaceV1SingletonPropsType
) {
  const { featureFlagService } = options;

  return new FeatureFlagInterfaceV1({
    featureFlagService
  });
}

export function getFeatureFlagInterfaceV1(): FeatureFlagInterfaceV1Type {
  return featureFlagInterfaceV1.getInterface();
}

export default async function initializeFeatureFlagInterfaceV1(
  options: CreateFeatureFlagInterfaceV1SingletonPropsType
): Promise<FeatureFlagInterfaceV1Type> {
  if (featureFlagInterfaceV1) return featureFlagInterfaceV1.getInterface();
  featureFlagInterfaceV1 = _createFeatureFlagInterfaceV1(options);

  return featureFlagInterfaceV1.getInterface();
}

/**
 * Create a bare-bones interface without any implementation. The term No-op
 * (NOP) stands for “no operation”.
 * @returns FeatureFlagInterfaceV1Type
 */
export function createNoopFeatureFlagsInterface(): FeatureFlagInterfaceV1Type {
  return {
    getClient: function (): Promise<FeatureFlagClientInterfaceV1Type> {
      console.debug('Function not implemented.');
      return Promise.resolve(null);
    },
    useReactFeatureFlag: function (): unknown {
      console.debug('Function not implemented.');
      return {};
    }
  };
}
