import {
  gqlType,
  GraphQLService as ShellLibraryGraphQLService
} from '@clientos/graphql-client';
import { Stack as ShellStack } from '../../types/stratus';
import { GraphQLPlatform, GraphQLStack } from './enums';
import IGraphQLService from './IGraphQLService';
import { getServices } from '../../infra/commonInitializer';
import bindAllMethods from '../../utils/bindAllMethods';
import { getJWeb } from '../JWeb';
import { GraphQLReactTools } from '@clientos/graphql-client/dist/react';
import { GraphQLServiceInputType } from '../../infra/commonInitializer/types';

class GraphQLService implements IGraphQLService {
  private _graphQLCoreMFePromise: Promise<void> | undefined;
  private _graphQLReactMFePromise: Promise<void> | undefined;
  private _graphQLClient: ShellLibraryGraphQLService;
  private _reactTools: GraphQLReactTools;
  private _graphqlInputs: GraphQLServiceInputType;

  constructor(graphqlInputs: GraphQLServiceInputType) {
    this._graphqlInputs = graphqlInputs;
  }

  public gql: gqlType;

  public async init(): Promise<void> {
    bindAllMethods(this);

    const { authProviderService, applicationService } = getServices();
    await getJWeb().then((JWeb) => {
      // Doc  JWeb.platform;: https://pages.github.azc.ext.hp.com/jarvis/jweb/4.2.0/docs/jarvis-plugins#how-to-detect-if-a-web-app-is-running-inside-a-jarvis-webview-
      const jWebPlatform = JWeb?.platform;

      const shellStack = applicationService.getPortalStack();
      const isShellPortal = !!applicationService.getClientId();

      // TODO: We will need to deal with the non 'web' platform as soon as we have the library instantiated in the native side.
      const platform = isShellPortal
        ? GraphQLPlatform.WEB
        : this._convertJWebPlatformToGraphQLPlatform(jWebPlatform);

      const stack = this._convertShellStackToGraphQLStack(shellStack);

      this._graphQLCoreMFePromise = System.import('@clientos/graphql-client')
        .then(async ({ createGraphQLClient, gql }) => {
          const graphQLClient = await createGraphQLClient({
            authTokenCallback: async () => {
              return authProviderService.getAccessToken();
            },
            mock: !!this?._graphqlInputs?.mock,
            platform,
            stack,
            isShellPortal,
            customDependencies: {
              JWeb
            }
          });

          this._graphQLClient = graphQLClient;
          this.gql = gql;
        })
        .catch((error) => {
          console.error('Failed to load "@clientos/graphql-client.');
          console.error(error);
        });

      this._graphQLReactMFePromise = System.import('@clientos/graphql-react')
        .then(({ WebGraphQLReactTools }) => {
          this._reactTools = WebGraphQLReactTools;
        })
        .catch((error) => {
          console.error('Failed to load @clientos/graphql-react.');
          console.error(error);
        });
    });

    await Promise.all([
      this._graphQLCoreMFePromise,
      this._graphQLReactMFePromise
    ]);
  }

  public getReactTools = (): GraphQLReactTools => {
    return this._reactTools;
  };

  public getClient = (): ShellLibraryGraphQLService => {
    return this._graphQLClient;
  };

  private _convertShellStackToGraphQLStack(
    shellStack: ShellStack
  ): GraphQLStack {
    switch (shellStack) {
      case ShellStack.dev:
        return GraphQLStack.DEV;
      case ShellStack.pie:
        return GraphQLStack.PIE;
      case ShellStack.stage:
        return GraphQLStack.STAGE;
      default:
        return GraphQLStack.PROD;
    }
  }

  private _convertJWebPlatformToGraphQLPlatform(
    jWebPlatform: string
  ): GraphQLPlatform {
    switch (jWebPlatform) {
      case 'ios':
        return GraphQLPlatform.IOS;
      case 'mac':
        return GraphQLPlatform.MAC;
      case 'windows':
        return GraphQLPlatform.WINDOWS;
      case 'android':
        return GraphQLPlatform.ANDROID;
      default:
        return GraphQLPlatform.WEB;
    }
  }
}

export default GraphQLService;
