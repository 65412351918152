import { internalLogger } from '../interface/v1/logger';
import { RepositoriesType } from './commonInitializer/RepositoriesType';
import { getRepositories } from './commonInitializer';

let repositories: RepositoriesType;

export const clearRepositories = (): void => {
  try {
    repositories = getRepositories();
  } catch (error) {
    internalLogger?.error?.(error);
  }

  const repositoriesToClear = Object.values(repositories);

  internalLogger?.log?.('clearRepositories');

  repositoriesToClear?.forEach((r) => r?.clear?.());
};
