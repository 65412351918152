import {
  GraphQLService,
  DocumentNode,
  ClientQueryResult,
  ClientFetchResult
} from '@clientos/graphql-client';

import bindAllMethods from '../../../utils/bindAllMethods';

class GraphQLNativeClient implements GraphQLService {
  constructor() {
    bindAllMethods(this);
  }

  public async query<TData = any, TVariables = any>(
    query: DocumentNode,
    variables?: TVariables
  ): Promise<ClientQueryResult<TData>> {
    return new Promise<ClientQueryResult<TData>>((resolve) => {
      // Recieve message with query payload logic
      const receiveMessage = (event) => {
        try {
          const { data } = event;
          if (data.type === 'queryResult' && data.query === query) {
            (window as any).removeEventListener('message', receiveMessage);
            resolve(data.payload);
          }
        } catch (e) {
          console.error('Error parsing message data:', e);
        }
      };
      (window as any).addEventListener('message', receiveMessage);

      //Post message to native requesting a query
      (window as any).ReactNativeWebView.postMessage(
        JSON.stringify({ type: 'query', payload: { query, variables } })
      );
    });
  }

  public async mutate<TData = any>(
    mutation: DocumentNode
  ): Promise<ClientFetchResult<TData>> {
    return new Promise<ClientFetchResult<TData>>((resolve) => {
      // Recieve message with mutation payload logic
      const receiveMessage = (event) => {
        try {
          const { data } = event;
          if (data.type === 'mutationResult' && data.mutation === mutation) {
            (window as any).removeEventListener('message', receiveMessage);
            resolve(data.payload);
          }
        } catch (e) {
          console.error('Error parsing message data:', e);
        }
      };
      (window as any).addEventListener('message', receiveMessage);

      //Post message to native requesting a mutation
      (window as any).ReactNativeWebView.postMessage(
        JSON.stringify({ type: 'mutation', payload: mutation })
      );
    });
  }
}

export default GraphQLNativeClient;
