import IApplicationService from '../../../services/applicationService/IApplicationService';
import bindAllMethods from '../../../utils/bindAllMethods';
import { AppInterfaceV1Type } from './types';

export type AppInterfaceV1Param = {
  applicationService: IApplicationService;
};

class AppInterfaceV1 {
  private _applicationService: IApplicationService;

  constructor({ applicationService }: AppInterfaceV1Param) {
    this._applicationService = applicationService;
    bindAllMethods(this);
  }

  public getInterface(): AppInterfaceV1Type {
    return {
      getClientId: () => {
        return this._applicationService.getClientId();
      },
      getAppName: () => {
        return this._applicationService.getAppName();
      },
      getAuthStack: () => {
        return this._applicationService.getAuthStack();
      },
      getPortalStack: () => {
        return this._applicationService.getPortalStack();
      }
    };
  }
}

export default AppInterfaceV1;
