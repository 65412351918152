import { getLocaleUrlFromAssetReference } from '../../../../utils/getLocaleUrlFromAssetReference';
import { getServices } from '../../../../infra/commonInitializer';
import { ILocalizationService } from '../../../../services/localizationService';
import { IMonitoringService } from '../../../../services/monitoringService';

export class PreFetchHandler {
  private _prefetchedList: Map<string, boolean> = new Map();
  private _localizationService: ILocalizationService;
  private _monitoringService: IMonitoringService;

  constructor() {
    const { localizationService, monitoringService } = getServices();
    this._localizationService = localizationService;
    this._monitoringService = monitoringService;
  }

  public preFetchAssetReference(
    assetReference: string,
    preloadAssetReferenceLocale: boolean
  ): void {
    if (assetReference && !this._prefetchedList.get(assetReference)) {
      this._prefetchedList.set(assetReference, true);
      const span = this._monitoringService?.startSpan?.('jshell-router', {
        'workflow.name': `downloading-mfe-${assetReference}`,
        'microfrontend.assetReference': assetReference
      });

      System.import(assetReference).finally(() => {
        span?.end?.();
      });

      if (preloadAssetReferenceLocale) {
        this.preFetchLocaleByAssetReference(assetReference);
      }
    }
  }

  public preFetchLocaleByAssetReference(assetReference: string): void {
    // TODO: retrieve this data from commonsAdapter.
    const importmap = (window as any).Shell.importmap;

    const locale = this._localizationService.getStringifyLocale();

    const urlLocale = getLocaleUrlFromAssetReference({
      importmap,
      assetReference,
      locale
    });
    if (urlLocale) this._localizationService.prefetchLocaleFile(urlLocale);
  }
}
