import { NodeClient } from '../../../clients/stratus/tntCommonResourceRegistry';
import { IAuthProviderService } from '../../../services/authProviderService';
import TenantUtils from '../TenantUtils';
import * as T from './types';

export default abstract class BaseStratusTenantHandler {
  _cachedPaginatedTenantList: T.PaginatedTenantVisualizationCacheType[] = [];

  _nodeClient: NodeClient;

  _authProvider: IAuthProviderService;
  _parentAuthProvider: IAuthProviderService;
  _stack: T.StackType;

  constructor({
    stack,
    authProvider,
    parentAuthProvider
  }: {
    stack: T.StackType;
    authProvider: IAuthProviderService;
    parentAuthProvider: IAuthProviderService;
  }) {
    this._stack = stack;
    this._authProvider = authProvider;
    this._parentAuthProvider = parentAuthProvider;
    this._nodeClient = new NodeClient(
      NodeClient.getBaseUrl(this._stack),
      this._parentAuthProvider
    );
  }

  public getTenantById = async (
    tenantId: string
  ): Promise<T.TenantVisualizationType> => {
    const tenantsPaginatedLists = this._cachedPaginatedTenantList.map(
      (paginations) => paginations.tenants
    );

    let tenant = tenantsPaginatedLists
      ?.flat()
      ?.find((tenant) => tenant.id === tenantId);

    if (!tenant) {
      const _options: T.GetTenantListOptionsType = {
        page: 0,
        tenantId
      };

      const paginatedTenantList = await this.getPaginatedTenantList(_options);

      const userTenantWithRoleCategory = paginatedTenantList?.tenants?.find(
        (tenant) => tenant?.id === tenantId
      );

      const tenantNode = await this._nodeClient.getNodeByTenantId({
        tenantId
      });

      tenantNode.roleCategory = userTenantWithRoleCategory?.roleCategory;

      tenant =
        TenantUtils._convertStratusTenantNodeToTenantVisualizationType(
          tenantNode
        );
    }

    return tenant;
  };

  public async getTenantList(
    refresh?: boolean
  ): Promise<T.TenantVisualizationType[]> {
    const tenantsCachedLists = refresh
      ? []
      : this._cachedPaginatedTenantList.map(
          (paginations) => paginations.tenants
        );
    let tenants;
    if (!tenantsCachedLists.length) {
      const paginatedTenantList = await this.getPaginatedTenantList({
        page: 0
      });
      tenants = paginatedTenantList?.tenants;
    } else {
      tenants = tenantsCachedLists?.flat();
    }
    //TODO console.warn to indicate that this list have only the nodes that were found by the pagination one
    return tenants;
  }

  public async getPaginatedTenantList(
    options: T.GetPaginatedTenantListOptionsType
  ): Promise<T.PaginatedTenantVisualizationType> {
    let result: T.PaginatedTenantVisualizationType;
    result = this._cachedPaginatedTenantList.find(
      //TODO: analyze semantic of currentPage at cached tenant list
      (tenantPage) => tenantPage.currentPage === options.page
    );
    if (!result) {
      result = await this._getPaginatedTenantListData(options);
      this._cachedPaginatedTenantList.push({
        parentTenantId: options.tenantId,
        ...result
      });
    }
    return result;
  }

  public abstract _getPaginatedTenantListData(
    options: T.GetPaginatedTenantListOptionsType
  ): Promise<T.PaginatedTenantVisualizationType>;
}
