import { getServices } from '../../../../../infra/commonInitializer';

type LoginRequiredInputType = {
  assetReference?: string;
  public?: boolean;
};

const isLoginRequired = async (
  object: LoginRequiredInputType
): Promise<boolean> => {
  if (object?.assetReference && !object?.public) {
    const { sessionService } = getServices();
    return !sessionService?.isLoggedIn?.();
  }
  return false;
};

export default isLoginRequired;
