export type Configuration = AuthProviderConfiguration | APIKeyConfiguration;
export interface BaseConfiguration {
  stack: Stack;
  dataIngressCustomEndpoint?: string;
  dataValveCustomEndpoint?: string;
  verboseLogging?: boolean;
  queueConfiguration?: QueueConfiguration;
  useValveControllerFiltering: boolean;
  useOneTrustFiltering: boolean;
  preConsentEventAccumulation?: boolean;
  /*
  * A boolean flag indicating whether the batching of events must be used or not, by default it is true
  */
  isBatchingEnabled?: boolean;
  batchConfiguration?: BatchConfiguration;
}
export interface QueueConfiguration {
  publishRetries?: number;
  publishRetryDelay?: number;
  queueItemTTLInHours?: number;
  queueSizeLimit?: number;
}

/*
*  configuration info for the batching of events
*/
export interface BatchConfiguration {
  maxEventCount?: number;
  minEventCount?: number;
  maxEventAge?: number;
  evaluationFrequency?: number;
}
export interface AuthProviderConfiguration extends BaseConfiguration {
  authProvider: AuthProvider;
}
export interface APIKeyConfiguration extends BaseConfiguration {
  telemetryAPIkey: string;
  valveControllerAPIkey: string;
  cacheControl: string;
}

export interface ConfigurationProvider {
  /**
   *
   * @return the configuration object
   */
  configuration: Configuration;
}

export enum Stack {
  local,
  dev,
  pie,
  staging,
  production,
  custom
}

export interface AuthProvider {
  getAccessToken: (forceRefresh?: boolean) => Promise<string>;
}

export enum QueueDefaults {
  PUBLISH_RETRIES = 3,
  PUBLISH_RETRY_DELAY = 30,
  QUEUE_ITEM_TTL_IN_HOURS = 24,
  QUEUE_SIZE_LIMIT = 1000
}

export enum BatchConfigDefaults {
  MAX_EVENT_COUNT = 250, // Default value for the maximum number of events that can be contained in a single Notification object
  MIN_EVENT_COUNT = 10, // Default value for the minimum number of Events to accumulate in a Notification prior publishing
  MAX_EVENT_AGE_IN_SECONDS = 300, // Default value for the maximum age allowed for an Event object is 5 minutes
  EVALUATION_FREQUENCY_IN_SECONDS = 60, // Default value for scheduled frequency with which to evaluate the batching logic is 1 minute
}

export const defaultIsBatchEnabled = true;

const defaultBatchConfiguration: BatchConfiguration = {
  maxEventCount: BatchConfigDefaults.MAX_EVENT_COUNT,
  minEventCount: BatchConfigDefaults.MIN_EVENT_COUNT,
  maxEventAge: BatchConfigDefaults.MAX_EVENT_AGE_IN_SECONDS,
  evaluationFrequency: BatchConfigDefaults.EVALUATION_FREQUENCY_IN_SECONDS
};

export const validateQueueConfigValue = (value: number | undefined, defaultValue: number): number => value !== undefined && value >= 0 ? value : defaultValue;

const validateBatchConfigValues = (defaultValue: number, value?: number) => value !== undefined && value > 0 && value <= defaultValue ? value : defaultValue;

export const validateBatchConfig = (batchConfig?: BatchConfiguration) => {
  if (batchConfig === undefined) return defaultBatchConfiguration;
  return {
    minEventCount: validateBatchConfigValues(BatchConfigDefaults.MAX_EVENT_COUNT, batchConfig.minEventCount),
    maxEventCount: validateBatchConfigValues(BatchConfigDefaults.MAX_EVENT_COUNT, batchConfig.maxEventCount),
    maxEventAge: validateBatchConfigValues(BatchConfigDefaults.MAX_EVENT_AGE_IN_SECONDS, batchConfig.maxEventAge),
    evaluationFrquency: validateBatchConfigValues(BatchConfigDefaults.EVALUATION_FREQUENCY_IN_SECONDS, batchConfig.evaluationFrequency),
  };
};
