import * as T from './types';
import initializeShellInterfaceV1 from './v1';
import v1Auth from './v1/auth';
import initializeShellInterfaceV2, { ShellInterfaceV2Type } from './v2';
import v2Auth from './v2/auth';
import { WebServiceType } from '../services/webServiceRouting/types';
import { getServices } from '../infra/commonInitializer';
import { initializeCommons } from '../infra/commonInitializer';
import { ShellInterfaceV1Type } from './v1/types';
import ManifestToCommonsAdapter from '../infra/commonInitializer/ManifestToCommonsAdapter';
import { CommonsInitializeInterfaceType } from '../infra/commonInitializer/types';

export type InterfacesType = {
  v1: ShellInterfaceV1Type;
  v2: ShellInterfaceV2Type;
  _shell: T.ShellStatesType;
};

const interfaces = {
  /** This property is only used internally, do not use this */
  _shell: {
    initialized: false,
    loading: false,
    error: false,
    initializers: {
      authProvider: {
        v1: v1Auth,
        v2: v2Auth
      }
    },
    init: async (initializerState: T.InterfacesInitializerStatePropsType) => {
      await initializeCommons(initializerState?.manifest);

      const commonsInitializeInterfaces: CommonsInitializeInterfaceType =
        ManifestToCommonsAdapter.convertToInterfaces(
          initializerState?.manifest
        );

      const { login: loginInput, onboarding: onboardingInput } =
        commonsInitializeInterfaces;

      const services = getServices();
      const { webServiceRouting } = services;

      if (!interfaces._shell?.initialized || !interfaces._shell?.loading) {
        // TODO: do a better solution to inject this dependencies
        webServiceRouting.addServices(
          onboardingInput?.stages?.map?.<WebServiceType>(
            ({ serviceId, ...stage }) => ({
              ...stage,
              id: serviceId,
              serviceType: 'web'
            })
          )
        );

        interfaces._shell.initialized = true;
        interfaces._shell.loading = true;
        interfaces._shell.error = false;

        const v1 = await initializeShellInterfaceV1(
          commonsInitializeInterfaces
        );
        const v2 = await initializeShellInterfaceV2(loginInput);

        interfaces.v1 = v1;
        interfaces.v2 = v2;

        interfaces._shell.loading = false;
      }
    }
  }
} as InterfacesType;

export default interfaces;
