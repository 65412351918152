import {
  jWebReady,
  CDMObject,
  DataCollectionEventNames, DataValveCDMEventData, EventInfo, FilterCDMTreesOptions,
  FilterCDMTreesResult,
  FilterLEDMTreesOptions,
  FilterLEDMTreesResult, IsDataAllowedOptions, IsDataAllowedResult, LEDMObject, Subscriber, ValveFilterErrorType, EventServicePluginError, ValveFilterError
} from '@jarvis/jweb-core';
import { bindingsMetadataKey, getConfigurationError, getWindowValues } from '../client/utils/enum';
import buildEnvelopAndSendNotification from '../helpers/buildEnvelopAndSendNotification';
import { getCachedBindings } from '../helpers/CacheStorage';
import { setInvalidateValveControllerMetaDataInstore } from '../helpers/checkInvalidateMetaData';
import { DataValveFilterError } from '../helpers/dataValveError';
import { oneTrustFiltering, valveControllerFiltering } from '../helpers/filteringMethods';
import { logger } from '../helpers/logger';
import isGetBindingsParamValid from '../helpers/validateData';
import { getApplicationContextFromValueStore, getValveControllerMetadataFromValueStore } from '../helpers/valueStoreHelpers';
import { sanitizeLEDM } from '../LEDMFilter/sanitize';
import { Queue } from '../Queue/Queue';
import { isDataCollectionPluginWeb } from '../web';
import { DataCollectionError } from '../helpers/dataCollectionError';
import { Accumulator, evaluateBatchLogic } from '../Queue/Accumulator';
import { QueueItemStatus } from '../Queue/queueHelpers';
import {
  Configuration, ConfigurationProvider, QueueDefaults, validateBatchConfig, defaultIsBatchEnabled, validateQueueConfigValue
} from './dataCollectionServiceTypes';

class DataCollectionServiceSingleton {
  configurationProvider: ConfigurationProvider | undefined;
  setConfigurationProvider(configurationProvider: ConfigurationProvider) {
    this.configurationProvider = configurationProvider;
    this.configurationProvider.configuration.preConsentEventAccumulation = configurationProvider?.configuration?.preConsentEventAccumulation ? configurationProvider.configuration.preConsentEventAccumulation : false;
    const { queueConfiguration } = configurationProvider.configuration;
    if (queueConfiguration) {
      Queue.publishRetries = validateQueueConfigValue(queueConfiguration.publishRetries, QueueDefaults.PUBLISH_RETRIES);
      Queue.queueItemTTLInHours = validateQueueConfigValue(queueConfiguration.queueItemTTLInHours, QueueDefaults.QUEUE_ITEM_TTL_IN_HOURS);
      Queue.publishRetryDelay = validateQueueConfigValue(queueConfiguration.publishRetryDelay, QueueDefaults.PUBLISH_RETRY_DELAY);
      Queue.queueSizeLimit = validateQueueConfigValue(queueConfiguration.queueSizeLimit, QueueDefaults.QUEUE_SIZE_LIMIT);
    }
    this.configurationProvider.configuration.isBatchingEnabled = configurationProvider.configuration.isBatchingEnabled !== undefined ? configurationProvider.configuration.isBatchingEnabled : defaultIsBatchEnabled;
    this.configurationProvider.configuration.batchConfiguration = validateBatchConfig(configurationProvider.configuration.batchConfiguration);
    // If any configuration parameters are changed, re-evaluate the batch logic
    evaluateBatchLogic();
    // When the “Batch Enabled” parameter is changed from true --> false, the queue should be flushed
    Accumulator.checkBatchEnabledAndFlush();
  }

  getConfiguration(): Configuration | undefined {
    return this.configurationProvider?.configuration;
  }

  async invalidateCache(): Promise<void> {
    const windowValue = getWindowValues();
    const previousBindingData = JSON.parse(windowValue.localStorage.getItem(bindingsMetadataKey));
    if (previousBindingData) {
      setInvalidateValveControllerMetaDataInstore(Object.keys(previousBindingData));
      windowValue.localStorage.removeItem(bindingsMetadataKey);
    }
    logger.log('DataCollectionService::invalidateCache:invalidateCache');
  }

  async filterCDMTrees(
    options: FilterCDMTreesOptions
  ): Promise<FilterCDMTreesResult> {
    const configuration = this.getConfiguration();
    const cdmRawObjects: (ValveFilterError | CDMObject)[] = options.cdmObjects;
    let filteredResults: (ValveFilterError | CDMObject)[];
    try {
      if (!configuration) {
        throw getConfigurationError();
      };
      const filterMetadata = await getValveControllerMetadataFromValueStore(options?.filterMetadata);
      const applicationContext = await getApplicationContextFromValueStore();
      // checking for json format
      let cdmObjects = cdmRawObjects.map(cdmObject => {
        if ((cdmObject as CDMObject).tree) {
          try {
            JSON.parse((cdmObject as CDMObject).tree);
          } catch (error: any) {
            cdmObject = new DataCollectionError({ functionName: 'filterCDMTrees', functionParameters: `CDMObject.treeGun=${(cdmObject as CDMObject).treeGun}` }, ValveFilterErrorType.treeNotAllowed, 'Json Parse error', error);
          }
        }
        return cdmObject;
      });
      filteredResults = cdmRawObjects.map(cdmObject => new DataCollectionError({ functionName: 'filterCDMTrees', functionParameters: `CDMObject.treeGun=${(cdmObject as CDMObject).treeGun}` }, ValveFilterErrorType.treeNotAllowed, 'Filtering not allowed', 'Invalid filtering flags'));
      if (configuration?.useOneTrustFiltering) {
        const consent = applicationContext && applicationContext?.webAppConsent ? applicationContext?.webAppConsent : '';
        const oneTrustResult = oneTrustFiltering(cdmObjects, consent);
        const result = oneTrustResult.map((res, index) => {
          if (res instanceof DataValveFilterError) {
            return new DataCollectionError({ functionName: 'filterCDMTrees', functionParameters: `CDMObject.treeGun=${(cdmObjects[index] as CDMObject).treeGun}` }, ValveFilterErrorType.treeNotAllowed, res.reason, 'Invalid resource id or Invalid telemetry consent');
          }
          return res as CDMObject;
        });
        filteredResults = result;
        cdmObjects = filteredResults;
      }
      if (configuration?.useValveControllerFiltering) {
        const bindingsParamsValidationResult = isGetBindingsParamValid(filterMetadata);
        if (bindingsParamsValidationResult === '') {
          const bindings = await getCachedBindings(filterMetadata);
          filteredResults = valveControllerFiltering(cdmObjects, bindings);
        } else {
          throw new DataValveFilterError(ValveFilterErrorType.valveControllerMetadataError, bindingsParamsValidationResult, 'Cannot call getBindings with invalid valveControllerMetadata');
        }
      }
    } catch (err: any) {
      let errorType = ValveFilterErrorType.treeNotAllowed;
      let reason = 'Unexpected Error';
      let exception = err;
      if (err instanceof DataValveFilterError) {
        errorType = err.errorType;
        reason = err.reason || '';
        exception = err.exception;
      }
      const cdmError = cdmRawObjects.map(cdmObject => new DataCollectionError({ functionName: 'filterCDMTrees', functionParameters: `CDMObject.treeGun=${(cdmObject as CDMObject).treeGun}` }, errorType, reason, exception));
      return { results: cdmError as [ValveFilterError] };
    }
    return {
      results: filteredResults as [ValveFilterError | CDMObject]
    };
  }

  async filterLEDMTrees(
    options: FilterLEDMTreesOptions
  ): Promise<FilterLEDMTreesResult> {
    const configuration = this.getConfiguration();
    // @ts-ignore
    try {
      if (!configuration) {
        throw getConfigurationError();
      }
      options.filterMetadata = await getValveControllerMetadataFromValueStore({ ...options.filterMetadata });
      const bindingsParamsValidationResult = isGetBindingsParamValid(options.filterMetadata);
      if (bindingsParamsValidationResult === '') {
        const bindings = await getCachedBindings(options.filterMetadata);
        const result = options.ledmObjects.map(ledmObject => {
          const ledmResult = sanitizeLEDM(ledmObject, bindings);
          if (ledmResult instanceof DataValveFilterError) {
            return { invocationDetails: { functionName: 'filterLEDMTrees', functionParameters: `LEDMObject.resourceUri=${ledmObject.resourceUri}` }, errorType: ledmResult.errorType, reason: ledmResult.reason, exception: ledmResult.exception };
          }
          return ledmResult;
        });
        logger.log('DataCollectionService::filterLEDMTrees::results:', result);
        return {
          results: result as [ValveFilterError | LEDMObject]
        };
      } else {
        throw new DataValveFilterError(ValveFilterErrorType.valveControllerMetadataError, bindingsParamsValidationResult, 'Cannot call getBindings with invalid valveControllerMetadata');
      }
    } catch (error: any) {
      let errorType = ValveFilterErrorType.treeNotAllowed;
      let reason = 'Unexpected Error';
      let exception = error;
      if (error instanceof DataValveFilterError) {
        errorType = error.errorType;
        reason = error.reason || '';
        exception = error.exception;
      }
      const cdmError = options.ledmObjects.map(ledmObject => new DataCollectionError({ functionName: 'filterLEDMTrees', functionParameters: `LEDMObject.resourceUri=${ledmObject.resourceUri}` }, errorType, reason, exception));
      return { results: cdmError as [ValveFilterError] };
    }
  }

  async isDataAllowed(options: IsDataAllowedOptions): Promise<IsDataAllowedResult> {
    const configuration = this.getConfiguration();
    const invocationDetails = { functionName: 'isDataAllowed', functionParameters: `IsDataAllowedOptions.dataCategory=${options.dataCategory}` };
    if (!configuration) {
      return {
        result: new DataCollectionError(invocationDetails, ValveFilterErrorType.configurationError, 'Minimum Configuration is required', 'configuration is missing')
      };
    }
    try {
      options.filterMetadata = await getValveControllerMetadataFromValueStore({ ...options.filterMetadata });
      const bindingsParamsValidationResult = isGetBindingsParamValid(options.filterMetadata);
      if (bindingsParamsValidationResult === '') {
        const results = await getCachedBindings(options.filterMetadata);
        if (results.allowedDataCategories) {
          const isDataAllowed = results.allowedDataCategories.includes(options.dataCategory);
          return {
            result: !!isDataAllowed
          };
        }
        return {
          result: new DataCollectionError(invocationDetails, ValveFilterErrorType.valveControllerAPIError, bindingsParamsValidationResult, 'ValveControllerAPIError', results)
        };
      } else {
        return {
          result: new DataCollectionError(invocationDetails, ValveFilterErrorType.valveControllerMetadataError, bindingsParamsValidationResult, 'Cannot call getBindings with invalid valveControllerMetadata')
        };
      }
    } catch (error: any) {
      logger.log('DataCollectionService::isDataAllowed::error:', error);
      return {
        result: new DataCollectionError(invocationDetails, ValveFilterErrorType.valveControllerAPIError, 'ValveControllerAPIError', error)
      };
    }
  }

  async handleCdmEvent(eventInfo: EventInfo) {
    if (eventInfo.eventName === DataCollectionEventNames.cdmEvent) {
      await buildEnvelopAndSendNotification(eventInfo.eventData as DataValveCDMEventData);
    }
  };

  async flushQueue() {
    await Accumulator.flushQueue();
  }

  async getPendingQueueItems(){
    const allItems = await Queue.getAllPendingItems(QueueItemStatus.pending);
    return allItems;
  }
}

const initializeService = async (service: DataCollectionServiceSingleton) => {
  jWebReady.then((jweb) => {
    const dataCollectionPlugin = jweb.Plugins?.DataCollection;
    const eventServicePlugin = jweb.Plugins?.EventService;
    if (dataCollectionPlugin === undefined || eventServicePlugin === undefined) {
      console.warn('DataCollectionService::initialize: Missing DataCollection and/or EventService plugins');
      return;
    }
    if (!isDataCollectionPluginWeb(dataCollectionPlugin) || dataCollectionPlugin.getDataCollectionService === undefined) {
      console.warn('DataCollectionService::initialize: Native DataCollection plugin active in the environment');
      return;
    }
    if (dataCollectionPlugin.getDataCollectionService() !== service) {
      console.warn('DataCollectionService::initialize: DataCollectionServiceSingleton instance mismatch');
      return;
    }
    eventServicePlugin
      .createSubscriber()
      .then((subscriberResult: Subscriber | EventServicePluginError) => {
        if ('subscribe' in subscriberResult) {
          subscriberResult
            .subscribe({ eventName: DataCollectionEventNames.cdmEvent }, service.handleCdmEvent)
            .then((_) => console.info('DataCollectionService::initialize: Subscribed to CDM events'))
            .catch((error: any) => console.error(`DataCollectionService::initialize::subscribe: ${error}`));
        } else {
          console.error(`DataCollectionService::initialize::createSubscriber: ${subscriberResult}`);
          return;
        }
      })
      .catch((error: any) => console.error(`DataCollectionService::initialize::createSubscriber: ${error}`));
  }).catch((error) => console.error(`DataCollectionService::initialize::jWebReady: ${error}`));
};

export const dataCollectionService = new DataCollectionServiceSingleton();

initializeService(dataCollectionService);
