import {
  EventSchemaVersionEnum,
  EventDataScreenNameEnum,
  EventDataScreenPathEnum
} from '../../../../../../../services/AnalyticsService/dependencies/AnalyticsEventEnums';

export const getLastDateAndTime = (session) => {
  const date = session?.context?.sessionContext?.updatedAt;

  if (date) {
    return (
      new Date(date)?.toLocaleDateString() +
      ' ' +
      new Date(date)?.toLocaleTimeString()
    );
  }
};

export const getProductName = (session) => {
  const modelName = session?.context?.sessionContext?.device?.modelName;
  const deviceName = session?.context?.sessionContext?.device?.deviceName;

  let result = ``;

  if (modelName) {
    result += modelName;
  }

  if (deviceName) {
    if (modelName) {
      result += ' - ';
    }
    result += deviceName;
  }

  return result;
};

export const createOnboardingSimpleUiEventData = ({ action, controlName }) => {
  return {
    action,
    screenPath: EventDataScreenPathEnum.portalOobe,
    screenName: EventDataScreenNameEnum.resumeSession,
    controlName,
    version: EventSchemaVersionEnum.simpleUi
  };
};
