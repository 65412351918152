import { ISessionService } from '../../services/session';
import { clearRepositories } from '../../infra/clearRepositories';

export type UseRepositoriesManagementRulesType = {
  sessionService: ISessionService;
};

export default class UseRepositoriesManagementRules {
  private _sessionService: ISessionService;

  constructor({ sessionService }: UseRepositoriesManagementRulesType) {
    this._sessionService = sessionService;
  }

  public init = (): void => {
    this._clearRepositories();
  };

  private _clearRepositories(): void {
    const isLoggedOut = this._isLoggedOut();
    const isInLoggedinPath = this._isInLoggedinPath();

    const clearRepositoriesRules = [isLoggedOut, isInLoggedinPath];

    if (clearRepositoriesRules.some((r) => r)) {
      clearRepositories();
    }
  }

  private _isLoggedOut(): boolean {
    const isLoggedin = this._sessionService.isLoggedIn();

    return !isLoggedin;
  }

  private _isInLoggedinPath(): boolean {
    const pathname = '/loggedin';
    const currentPath = window.location.pathname;

    return currentPath?.includes(pathname);
  }
}
