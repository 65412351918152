import { writeDataToValueStore } from '../../../JWeb';
import {
  DataCollectionEventNames,
  AssetUnitType,
  ReservedValueStoreKeys
} from '../../../JWeb/JWebEnums';
import {
  CDMEventData,
  DataValveCDMEventData,
  Publisher
} from '../../../JWeb/types';
import Store from '../../../../interface/v1/store/index';
import {
  DataValveCDMEvent,
  GenericPublisher,
  PublishCdmEventsOptions,
  AnalyticsServiceInputType
} from '../../types';
import IAnalyticsService from '../../IAnalyticsService';
import EventNames from '../../../../config/eventNames';
import { createCDMEvent } from '../../dependencies/AnalyticsEventBuilder';
import { EventCategoryEnum } from '../../dependencies/AnalyticsEventEnums';
import { hasErrorInEventServicePublisher } from './dependencies/utils';
import VisitUuidHandler from './dependencies/visitUuidHandler';
import { SetServiceDependencies } from '../../../../infra/commonInitializer/types';
import bindAllMethods from '../../../../utils/bindAllMethods';
import { EventServiceValueType } from '../../../eventService/types';
import { internalLogger } from '../../../../interface/v1/logger';
import {
  DataCollectionRecorderWeb,
  DataCollectionRecorderNative
} from './dependencies/DataCollectionRecorder';
import { jWebStates } from '../../../JWeb/initializer';
import { IMonitoringService } from '../../../monitoringService';
import getFlatMap from '../../../../utils/getFlatMap';
import * as JwebEventService from '../../../JWeb/eventService';
import IEventService from '../../../eventService/IEventService';
import { OPT_IN_GROUP_VALUE } from '../../analyticsServiceConstants';

// TODO: isolate this method.
async function _publishWeb(
  publisherWeb: Publisher,
  eventName: string,
  eventData: any
): Promise<void> {
  // validation only used in Web
  if ((window as any)._O?.ifOptInGroups(OPT_IN_GROUP_VALUE)) {
    await publisherWeb.publish(eventName, eventData);
  } else {
    internalLogger?.warn?.(
      'Analytics: Event(s) not sent. No AnalyticsOptInGroup.'
    );
  }
}

export default class DefaultAnalyticsService implements IAnalyticsService {
  private _isEnabled = false;
  private _isNative = false;
  private _debugEnabled = false;
  private _dataCollectionRecorder = undefined; /// Only for Web
  private _visitUuidHandler;
  private _version = '1.0.0.0';
  private _store: Store;
  private _events: IEventService;
  private _publisher: GenericPublisher;
  private _monitoringService: IMonitoringService;

  constructor(analyticsProps: AnalyticsServiceInputType) {
    const { enabled, isNative } = analyticsProps || {};
    this._isEnabled = enabled;
    this._isNative = isNative || false;
    this._debugEnabled = analyticsProps.publishErrors;

    this._visitUuidHandler = new VisitUuidHandler(
      isNative,
      analyticsProps.userActivity
    );
    this._dataCollectionRecorder = !this._isNative
      ? new DataCollectionRecorderWeb(analyticsProps)
      : new DataCollectionRecorderNative();

    bindAllMethods(this);
  }

  public setDependencies({ services }: SetServiceDependencies): void {
    this._events = services.eventService;
    this._monitoringService = services.monitoringService;
    this._visitUuidHandler.setDependencies({ services });
  }

  // TODO we should remove this ASAP
  public setInterfaceDependencies({
    authProvider,
    store
  }: Parameters<IAnalyticsService['setInterfaceDependencies']>[0]): void {
    this._store = store;
    this._dataCollectionRecorder.setInterfaceDependencies({ authProvider });
  }

  public isEnabled(): boolean {
    return this._isEnabled;
  }

  public async isEnabledWithAllPlugins(): Promise<boolean> {
    if (!this.isEnabled()) {
      return false;
    }

    try {
      const JwebStatePromiseList = [
        jWebStates.JWebLibrary.then(() => true).catch(() => 'JWebLibrary'),
        jWebStates.defaultPluginsRegistered
          .then(() => true)
          .catch(() => 'defaultPluginsRegistered'),
        jWebStates.plugins.ValueStore.then(() => true).catch(
          () => 'ValueStore'
        ),
        jWebStates.plugins.EventService.then(() => true).catch(
          () => 'EventService'
        ),
        jWebStates.plugins.DataCollection.then(() => true).catch(
          () => 'DataCollection'
        )
      ];

      const results = await Promise.all(JwebStatePromiseList);
      const failedPlugins = results.filter(
        (result) => typeof result === 'string'
      );

      if (failedPlugins.length > 0) {
        console.error(
          'Unable to run Analytics. Some JWeb Plugin is missing: ',
          failedPlugins
        );
        this._isEnabled = false;
        return false;
      } else {
        return true;
      }
    } catch (e) {
      console.error('Unable to run Analytics. Something went wrong', e);
      this._isEnabled = false;
      return false;
    }
  }

  public async init(): Promise<void> {
    // Get Publisher
    this._publisher = await this._getPublisher();

    // Checking if all JWEB dependences are ready.
    // TODO: I can't lock this call, because the commons uses await in this init().
    this.isEnabledWithAllPlugins();

    // Initialize Sub-Components
    this._visitUuidHandler.init();
    await this._dataCollectionRecorder.init();

    // Subscribe Events
    this._subscribeEvents();
  }

  private async _getPublisher(): Promise<GenericPublisher> {
    try {
      if (!this._isNative) {
        // Publisher for WEB
        // TODO: simplify this method and check this EventServicePlugin
        const eventServicePlugin =
          await JwebEventService.getEventServicePlugin();
        const publisherId = `${window.location.hostname}.publisher`;

        const publisherWeb = (await eventServicePlugin.createPublisher(
          publisherId
        )) as Publisher;
        return {
          publish: (eventName, eventData) =>
            _publishWeb(publisherWeb, eventName, eventData)
        } as GenericPublisher;
      } else {
        // Publisher for NATIVE
        return this._events as GenericPublisher;
      }
    } catch (e) {
      internalLogger?.error?.('Analytics: getPublisher Error: ', e);
      this._isEnabled = false;
    }
  }

  public async publishCdmEvents(
    events: DataValveCDMEvent[],
    options?: PublishCdmEventsOptions
  ): Promise<void> {
    try {
      if (!this.isEnabled()) return;

      await this._visitUuidHandler.isVisitUUIDReady;

      if (hasErrorInEventServicePublisher(this._publisher)) return;

      internalLogger?.debug?.('Analytics: Publisher was found.');

      await this._writeAnalyticsDataToValueStore(options);
      await this._dataCollectionRecorder.updateDataCollectionService();

      const eventName = DataCollectionEventNames.cdmEvent;
      const eventData: DataValveCDMEventData = {
        events: events as [CDMEventData],
        trackingIdentifier: options?.metadata?.trackingIdentifier,
        ...(!this._isNative && {
          valveControllerMetadata: {
            assetUnit: AssetUnitType.desktop
          }
        })
      };

      // Publishes event
      internalLogger?.debug?.(
        'Analytics: publishCdmEvents is calling Publish method. '
      );
      await this._publisher.publish(eventName, eventData);
    } catch (e) {
      internalLogger?.error?.('Analytics: publishCdmEvents Internal Error', e);
    }
  }

  private async _writeAnalyticsDataToValueStore(
    options?: PublishCdmEventsOptions
  ): Promise<void> {
    const hostname = window.location.hostname;

    //https://hp-jira.external.hp.com/browse/JSHELL-3536
    await writeDataToValueStore([
      {
        key: ReservedValueStoreKeys.webApplicationName,
        value: hostname
      },
      {
        key: ReservedValueStoreKeys.webApplicationVersion,
        value: this._version
      },
      {
        key: ReservedValueStoreKeys.selectedPrinterUuid,
        value:
          options?.metadata?.associatedDeviceUuid ||
          this._store.state.onboarding?.sessionContext?.device?.uuid
      },
      {
        key: ReservedValueStoreKeys.selectedPrinterModelNumber,
        value:
          options?.metadata?.associatedDeviceProductNumber ||
          this._store.state.onboarding?.sessionContext?.device?.productNumber
      },
      {
        key: ReservedValueStoreKeys.ucdeCorrelationId,
        value:
          options?.metadata?.xCorrelationId ||
          this._store.state.onboarding?.sessionContext?.xCorrelationId
      }
    ]);
  }

  /**
   *
   * TODO: In the following, everything could be considered as a behavior of Analytics.
   * */
  public callbackShellSimpleUiEvent(event: EventServiceValueType): void {
    this.publishCdmEvents([
      createCDMEvent(EventCategoryEnum.simpleUi, event?.eventData)
    ]);
  }

  public callbackPublishNotificationError(event: EventServiceValueType): void {
    const { eventData } = event || {};
    if (!eventData) return;

    const isPublished = eventData.action === 'publishNotification';
    const is200Code =
      eventData.actionDetail?.telemetryServiceResponse?.responseCode == 200;

    if (isPublished && !is200Code) {
      // We found an error, so will write it on our monitoring service.
      const dataToBeSaved = Object.fromEntries(
        getFlatMap(eventData.actionDetail)
      );

      this._monitoringService
        .startSpan('jshell-analytics', dataToBeSaved)
        .end();
    }
  }

  private async _subscribeForCmdEventsStratus(): Promise<void> {
    const subscriber = await JwebEventService.getSubscribe();
    subscriber.subscribe(
      { eventName: DataCollectionEventNames.cdmEventStatus },
      this.callbackPublishNotificationError
    );
  }

  private _subscribeEvents() {
    // Use case #1: When should send the Simple UI event.
    this._events.subscribe(
      EventNames.shellAnalyticsSimpleUiEvent,
      this.callbackShellSimpleUiEvent
    );

    // Use case #2: When should send the errors from Data Collection.
    if (this._debugEnabled) {
      this._subscribeForCmdEventsStratus();
    }
  }
}
