import IAuthorizationUrlBuilder from './IAuthorizationUrlBuilder';
import { Stack } from '../../../types/stratus';
import { deleteCookie, getCookie } from '../../../utils/cookies';
import encryptVisitorUUID from '../utils/encryptVisitorUUID';
import { hpidAdditionalSearchParams } from './constants';
import StateParamHandlerService from '../../../services/stateParamHandlerService/StateParamHandlerService';
import { StatesParamType } from '../../../services/stateParamHandlerService/types';

class AuthorizationUrlBuilder implements IAuthorizationUrlBuilder {
  private _urlParams: URLSearchParams;
  private readonly _authorizationUrl: string;
  private _stateParamHandlerService: StateParamHandlerService;

  constructor(
    authorizationUrl: string,
    stateParamHandlerService: StateParamHandlerService
  ) {
    this._urlParams = new URLSearchParams();
    this._authorizationUrl = authorizationUrl;
    this._stateParamHandlerService = stateParamHandlerService;
  }

  withLoginHint(): IAuthorizationUrlBuilder {
    const loginHintKey = 'login_hint';
    const login_hint = new URLSearchParams(this._authorizationUrl).get(
      loginHintKey
    );

    if (login_hint) {
      this._urlParams.append('target', 'password');
    }
    return this;
  }

  withState(
    postLoginRedirect: string,
    language: string,
    country: string
  ): IAuthorizationUrlBuilder {
    const defaultPostLoginRedirect =
      country && language ? `/${country}/${language}/` : undefined;
    const stateObject: StatesParamType =
      this._stateParamHandlerService.createLoginStateObject(
        postLoginRedirect || defaultPostLoginRedirect
      );

    this._urlParams = new URLSearchParams({
      ...stateObject,
      ...Object.fromEntries(this._urlParams)
    });

    return this;
  }

  async withVisitorUUID(stack: Stack): Promise<void> {
    let encryptedVisitorUUID: string = null;
    const optanonConsent = getCookie('OptanonConsent');
    const consentIdCookiePropertyValue =
      optanonConsent?.match(/consentId=(\S{36})/)?.[1];

    if (!consentIdCookiePropertyValue) {
      return;
    }

    try {
      encryptedVisitorUUID = await encryptVisitorUUID({
        stack: stack,
        visitorUuid: consentIdCookiePropertyValue
      });
      console.log('encryptedVisitorUUID2', encryptedVisitorUUID);
    } catch (e) {
      console.error(e);
    }

    if (encryptedVisitorUUID) {
      this._urlParams.append('appInstanceId', encryptedVisitorUUID);
    }
  }

  withAuthorizationExtraParams(): IAuthorizationUrlBuilder {
    const authorizationExtraParams: Record<string, string> = (() => {
      const result: Record<string, string> = {};

      new URLSearchParams(window.location.search).forEach((v, k) => {
        if (hpidAdditionalSearchParams.find((key) => key === k)) {
          result[`${k}`] = v;
        }
      });

      return {
        ...result
      };
    })();

    this._urlParams = new URLSearchParams({
      ...Object.fromEntries(this._urlParams),
      ...authorizationExtraParams
    });

    return this;
  }

  withMaxAgeZeroOnLogin(): IAuthorizationUrlBuilder {
    const cookieName = 'jarvisreactshelllogin__setMaxAge0OnLogin';

    const cookieExists = getCookie(cookieName) === 'true';
    if (cookieExists) {
      this._urlParams.append('max_age', '0');
      deleteCookie(cookieName);
    }
    return this;
  }

  withLocale(language: string, country: string): IAuthorizationUrlBuilder {
    if (!language || !country) return;
    const currentLocale = `${language.toLowerCase()}-${country.toUpperCase()}`;
    this._urlParams.append('locale', currentLocale);
    return this;
  }

  build(): string {
    return `${this._authorizationUrl}&${this._urlParams.toString()}`;
  }
}

export default AuthorizationUrlBuilder;
